import axios from 'axios';
import copy from 'copy-to-clipboard';

export default {
	name: 'admin-generate',
	props: ['hash', 'phash', 'type', 'hide', 'limit', 'compact'],
	data: function () {
		return {
			url: null,
			copied: false
		}
	},
	computed: {
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		generate: function () {
			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/generate', {
				hash: this.limit ? this.hash.split('-')[0] + '-gen-' + (new Date().getTime()) : this.hash,
				phash: this.phash,
				type: this.type,
				token: this.token,
				limit: this.limit
			}).then((response) => {
				this.url = response.data.url;

				this.$emit('update', {
					hash: this.hash,
					phash: this.phash,
					token: this.url
				});
			});
		},
		copyLink: function (path) {
			copy(path);
			this.copied = true;

			this.$store.dispatch("ge", {
				action: "copy",
				category: "share",
				label: this.$route.fullPath
			});

			setTimeout(() => this.copied = false, 2000);
		}
	}
};
