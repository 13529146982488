import {betterURL} from '@/common/helpers';

export default {
	name: 'layout-regions',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		okresy: function () {
			return this.$store.getters.getSource('obecne/okresy-flat');
		},
		listOfTowns: function () {
			return this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')
		},
		towns: function () {
			var list = [];

			if (this.listOfTowns) {
				Object.keys(this.listOfTowns.content.list).forEach(key => {
					this.listOfTowns.content.list[key].forEach(town => {
						if (town[4] != 1 && town[4] != 5) list.push(town)
					})
				});
			}

			list.sort((a, b) => a[6].localeCompare(b[6], 'cs'));

			return list;
		}
	},
  methods: {
		betterURL
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Rejstřík obcí, kde se konají volby"});
  }
};
