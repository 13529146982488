import {betterURL} from '@/common/helpers';

export default {
	name: 'maps-towns',
	props: ['data', 'showTowns', 'showTownParts', 'townSizeGap', 'country'],
	components: {},
	data: function () {
		return {
			gap: 0,
			width: 0,
			height: 0,
			ratio: 0,
			show: false
		}
	},
	computed: {
		limits: function () {
			var xmin = 12;
			var xmax = 18.9;
			var ymin = 48.5;
			var ymax = 51.06;

			if (typeof this.country === "undefined") {

				xmin = 90;
				xmax = 0;
				ymin = 90;
				ymax = 0;

				this.data.forEach(item => {

					var list = 'point';

					if (!item.point && item.data) list = 'data';

					if (xmin > item[list][2]) xmin = item[list][2];
					if (xmax < item[list][2]) xmax = item[list][2];
					if (ymin > item[list][3]) ymin = item[list][3];
					if (ymax < item[list][3]) ymax = item[list][3];
				});
			}

			var ratio = (xmax - xmin) / (ymax - ymin);

			return [[xmin, xmax], [ymin, ymax], [xmax - xmin, ymax - ymin], ratio]
		},
		points: function () {
			var list2 = [];
			var y = 0;

			if (this.show && this.data && this.data.length && this.data.length > 0) {
				this.data.forEach(town => {

					var list = 'point';

					if (!town.point && town.data) list = 'data';

					var obj = {
						name: town[list][6],
						space: town[list][5],
						size: town[list][5],
						active: 24,
						type: town[list][4],
						x: 0,
						y: 0,
						link: '/obec/' + town[list][0] + '-' + betterURL(town[list][6]),
						data: town.data
					}

					if (this.townSizeGap) {
						if (town[list][5] < this.townSizeGap) {
							obj.space = obj.space * 0.2;
						} else {
							obj.space = obj.space * 0.3;
						}
					}

					obj.x = this.gap + Math.round((town[list][2] - this.limits[0][0]) / this.limits[2][0] * this.width);
					obj.y = this.gap + Math.round((this.limits[2][1] - (town[list][3] - this.limits[1][0])) / this.limits[2][1] * this.width / 1.6);

					if (obj.y > y) y = obj.y;

					list2.push(obj);
				});
			}

			list2.sort((a, b) => b.y - a.y)

			this.height = y + this.gap;

			if (this.country) this.setHeight();

			return list2;
		}
	},
	methods: {
		setHeight: function () {
			var w = this.$el.getBoundingClientRect().width;
			this.width = Math.round(w * .9);
			this.gap = Math.round(w * .05);

			this.height = this.width * .701;

			// this.height = this.width;
			//
			// if (window.innerHeight < this.height) this.height = Math.round(window.innerHeight * 0.8);
			//
			// this.ratio = this.width / this.height;
		}
	},
	mounted: function () {
		this.setHeight();

		window.addEventListener("resize", () => this.setHeight())

		setTimeout(() => this.show = true, 1000);
	}
};
