import {betterURL} from '@/common/helpers';

export default {
	name: 'Block',
	props: ['level', 'headline', 'anchor', 'alert', 'subheadline', 'hide', 'anchorLabel'],
	computed: {
		hash: function () {
			return this.anchor || betterURL(this.headline)
		}
	}
};
