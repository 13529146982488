export default [
  {num: 582786, type: 1, display: "Brno", current: "Markéta Vaňková", party: 53, coalition: ["ODS s podporou Svobodných", 7, 720, 1]},
  {num: 561380, type: 2, display: "Česká Lípa", current: "Jitka Volfová", party: 768, coalition: [768, 720, "NaS - Nezávislí a soukromníci", "ŽIVÁ LÍPA"]},
  {num: 544256, type: 1, display: "České Budějovice", current: "Jiří Svoboda", party: 768, coalition: [768, "OBČANÉ PRO BUDĚJOVICE", "Společně pro Budějovice - Lidovci a TOP 09", "STAROSTOVÉ A NEZÁVISLÍ a Čisté Budějovice"]},
  {num: 562335, type: 2, display: "Děčín", current: "Jiří Anděl", party: 768, coalition: [768, 53, 720, "Změna pro Děčín", "Volba pro Děčín"]},
  {num: 598003, type: 2, display: "Frýdek-Místek", current: "Petr Korč", party: {short: 'NMFM'}, coalition: ['NMFM', 720, 768]},
  {num: 555088, type: 2, display: "Havířov", current: "Josef Bělica", party: 768, coalition: [768, 7, "Společně pro Havířov - Koalice KDU-ČSL a STAN", "Hnutí pro Havířov"]},
  {num: 569810, type: 1, display: "Hradec Králové", current: "Alexandr Hrabálek", party: 53, coalition: [53, 768]},
  {num: 562971, type: 2, display: "Chomutov", current: "Marek Hrabáč", party: 768, coalition: [768, 47, "NOVÝ SEVER"]},
  {num: 563510, type: 2, display: "Jablonec n.N.", current: "Jiří Čeřovský", party: 53, coalition: [53, 703, 768]},
  {num: 586846, type: 1, display: "Jihlava", current: "Karolína Koubová", party: 161, coalition: ["FÓRUM JIHLAVA", 53, 1, "ŽIJEME JIHLAVOU!"]},
  {num: 554961, type: 1, display: "Karlovy Vary", current: "Andrea Pfeffer Ferklová", party: 768, coalition: [768, 1028, "Občanská demokratická strana s podporou Strany soukromníků ČR a Mladých konzervativců"]},
  {num: 598917, type: 2, display: "Karviná", current: "Jan Wolf", party: 7, coalition: [7, 768, 47]},
  {num: 532053, type: 2, display: "Kladno", current: "Milan Volf", party: {short: 'VPK'}, coalition: ["VOLBA PRO KLADNO", 768, 47]},
  {num: 563889, type: 1, display: "Liberec", current: "Jaroslav Zámečník", party: 703, coalition: [703, 53, 768]},
  {num: 535419, type: 2, display: "Mladá Boleslav", current: "Raduan Nwelati", party: 53, coalition: [53, 7, 166, "ANO 2011 + Volba pro Mladou Boleslav"]},
  {num: 567027, type: 2, display: "Most", current: "Jan Paparega", party: {short: 'ProMOST'}, coalition: ["ProMOST"]},
  {num: 500496, type: 1, display: "Olomouc", current: "Miroslav Žbánek", party: 768, coalition: [768, 1, 53]},
  {num: 505927, type: 2, display: "Opava", current: "Tomáš Navrátil", party: 768, coalition: [768, 7, 1, "Zelená pro Opavu, sdružení Strany zelených a nezavislých kandidátů", "Občané městských částí Opavy"]},
  {num: 554821, type: 1, display: "Ostrava", current: "Tomáš Macura", party: 768, coalition: [768, 53, 720, "KDU-ČSL a nezávislí kandidáti"]},
  {num: 555134, type: 1, display: "Pardubice", current: "Martin Charvát", party: 768, coalition: [768, 53, "Koalice pro Pardubice", "Česká strana sociálně demokratická a nestraníci pro Pardubice"]},
  {num: 554791, type: 1, display: "Plzeň", current: "Martin Baxa", party: 53, coalition: [53, 721, 768, 7]},
  {num: 554782, type: 0, display: "Praha", current: "Zdeněk Hřib", party: 720, coalition: [720, "PRAHA SOBĚ", "TOP 09 a Starostové (STAN) ve spolupráci s KDU-ČSL, LES a Demokraty Jana Kasla -\"Spojené síly pro Prahu\""]},
  {num: 589250, type: 2, display: "Prostějov", current: "František Jura", party: 768, coalition: [768, 7, "Občanská demokratická strana a nezávislé osobnosti města Prostějova", "Pévéčko"]},
  {num: 511382, type: 2, display: "Přerov", current: "Petr Měřínský", party: 768, coalition: [768, 53, "KDU-ČSL a TOP09"]},
  {num: 552046, type: 2, display: "Tábor", current: "Štěpán Pavlík", party: 136, coalition: [136, 720, 7]},
  {num: 567442, type: 2, display: "Teplice", current: "Hynek Hanza", party: 53, coalition: [53, 768]},
  {num: 590266, type: 2, display: "Třebíč", current: "Pavel Pacal", party: {short: 'Pro Třebíč'}, coalition: ['Pro Třebíč', 768, 7, 1, "ODS a TnT - Společně za Třebíč!", "Břehy", "Třebíč můj domov"]},
  {num: 598810, type: 2, display: "Třinec", current: "Věra Palkovská", party: {short: 'OSOBNOSTI PRO TŘINEC'}, coalition: ['OSOBNOSTI PRO TŘINEC', 1]},
  {num: 554804, type: 1, display: "Ústí n.L.", current: "Petr Nedvědický", party: 768, coalition: [768, 53, "PRO Zdraví a Sport"]},
  {num: 585068, type: 1, display: "Zlín", current: "Jiří Korec", party: 768, coalition: [768, 166, 53, 1]},
]
