import {truncate, csuColor, csuLogo, betterURL} from '@/common/helpers';

export default {
	name: 'leader-tiny-item',
	props: ['data', 'links', 'photo', 'motto', 'base'],
	data: function () {
		return {
			buttons: [
				// {type: 'people'},
				// {type: 'web'},
				{type: 'fb'},
				{type: 'tw'},
				{type: 'ig'},
				{type: 'yt'},
				{type: 'linkedin'},
				{type: 'wiki'},
				// {type: 'link'}
			]
		}
	},
	computed: {
		color: function () {
			return csuColor(this.data); // colorOfParty(this.data.party, (typeof this.data.party === 'string' ? JSON.parse(this.data.party).short : 'xxx'), this.data.color)
		},
		logo: function () {
			return csuLogo(this.data)
		},
		logoAll: function () {
			return this.data ? csuLogo(this.data, true) : null;
		},
		link: function () {
			var parts = ['/obec'];
			var obec = this.$store.getters.town(this.data.csu.main.num);

			parts.push(obec.hash);
			parts.push(this.data.csu.main.hash);

			return parts.join('/');
		}
	},
	methods: {
		csuColor, csuLogo, truncate, betterURL
	}
};
