import {date, createColorByName, gradient} from "@/common/helpers";
import ResultsGraph from "@/components/results-graph/do";

export default {
	name: 'PollsEntry',
	props: ['poll', 'hash', 'open'],
	data: function () {
		return {
			show: false
		}
	},
	components: {
		ResultsGraph
	},
	methods: {
		date,
		toggleShow: function () {
			this.show = !this.show;
		},
		colorOfParty: function (party, name) {

			var o = {color: '#ddd'};

			if (party) {
				var p;

				if (typeof party === 'number') {
					// console.log(item.party);
					p = this.$store.getters.party(party);

					if (p) {
						o.color = p.coalition ? gradient(p) : p.color;
					}
				}
				if (typeof party === 'string') {
					var json = JSON.parse(party);

					if (json.color) {
						if (typeof json.color === 'number') {
							p = this.$store.getters.party(json.color);

							if (p) {
								o.color = p.color;
							}
						} else {
							o.color = json.color;
						}
					}
				}
				if (typeof party === 'object') {

					if (party.color) {
						o.color = p.color;
					} else if (party.reg) {
						p = this.$store.getters.party(party.reg);

						if (p) {
							o.color = p.color;
						}
					} else if (party.coalition) {
						o.color = gradient(party);
					}
				}
			}

			if ((!party || party === 90 || party === 80) && name) {
				o.color = createColorByName(name);
			}

			if (o.color === '#ddd' && name) {
				o.color = createColorByName(name);
			}

			return o.color;
		},
	},
	computed: {
		color: function () {
			return '#000';
		},
		// parties: function () {
		// 	// return this.$store.getters.pdv('party/list')
		// },
		data: function () {
			if (!this.poll) return undefined;

			var list = [];
			var about = {};

			var p = this.poll;

			about.agency = p.agency;
			about.date = p.date;
			about.meta = p.meta;
			about.source = p.source;
			about.comment = p.comment;

			p.results.forEach(item => {
				var obj = {item};

				obj.short = item.name;
				obj.pct = item.value;

				if (item.reg) {
					obj.color = this.$store.getters.party(item.reg).color;
				} else {
					obj.color = this.colorOfParty(item, item.name);
				}

				list.push(obj);
			});

			// Object.keys(p).forEach(key => {
			// 	if (['agency', 'date', 'link', 'type', 'error', 'collect_from', 'collect_to', 'attendance'].indexOf(key) === -1 && p[key] != null) {
			// 		var obj = {
			// 			hash: key
			// 		};
			//
			// 		var party = this.parties.list.find(x => x.hash === key);
			//
			// 		if (party) {
			// 			obj.color = !this.hash || this.hash === party.hash ? party.color : '#ccc';
			// 			obj.logo = this.$store.state.server + party.logo;
			// 			obj.pct = p[key];
			// 			obj.short = party.short;
			// 		}
			//
			// 		list.push(obj);
			// 	}
			// });

			// list.sort((a, b) => b.pct - a.pct);

			return {list, about};
		}
	},
	mounted: function () {
		if (this.open) this.show = true;
	}
};
