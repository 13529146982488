import Vue from 'vue';

import ReportBlock from './report-block/do';
import TruncatedText from './truncated-text/do';
import ScrollableTable from './scrollable-table/do';
import TipForm from './tip-form/do';
import NewsItem from './news-item/do';
import MapTowns from './map-towns/do';
import LeaderListItem from './leader-list-item/do';
import SenateListItem from './senate-list-item/do';
import LeaderGridItem from './leader-grid-item/do';
import SenateGridItem from './senate-grid-item/do';
import LeaderTinyItem from './leader-tiny-item/do';
import SenateTinyItem from './senate-tiny-item/do';
import LeaderResultItem from './leader-result-item/do';
import SenateResultItem from './senate-result-item/do';
import SearchTown from './search-town/do';
import SearchParty from './search-party/do';
import ImageUpload from './image-upload/do';
import RoundButton from './round-button/do';
import LinkAdd from './link-add/do';
import ContactAdd from './contact-add/do';
import HashUpdate from './hash-update/do';
import TopicSuggest from './topic-suggest/do';
import VoteElement from './vote-element/do';
import VoteElementMultiple from './vote-element-multiple/do';
import VoteGroup from './vote-group/do';
import RequestAccess from './request-access/do';
import PersonLineSummary from './person-line-summary/do';
import PersonSummary from './person-summary/do';
import QuoteArrow from './quote-arrow/do';
import QuoteBlock from './quote-block/do';
import CandidateStats from './candidate-stats/do';
import ViewSwitch from './view-switch/do';
import GridOrList from './grid-or-list/do';
import AnswerBlock from './answer-block/do';
import FaqBlock from './faq-block/do';
import HistoryTown from './history/town/do';
import MandatesCalc from './mandates-calc/do';
import MayorItem from './mayor-item/do';

Vue.component('report-block', ReportBlock);
Vue.component('truncated-text', TruncatedText);
Vue.component('scrollable-table', ScrollableTable);
Vue.component('tip-form', TipForm);
Vue.component('news-item', NewsItem);
Vue.component('map-towns', MapTowns);
Vue.component('leader-list-item', LeaderListItem);
Vue.component('senate-list-item', SenateListItem);
Vue.component('leader-grid-item', LeaderGridItem);
Vue.component('senate-grid-item', SenateGridItem);
Vue.component('leader-tiny-item', LeaderTinyItem);
Vue.component('senate-tiny-item', SenateTinyItem);
Vue.component('leader-result-item', LeaderResultItem);
Vue.component('senate-result-item', SenateResultItem);
Vue.component('search-town', SearchTown);
Vue.component('search-party', SearchParty);
Vue.component('image-upload', ImageUpload);
Vue.component('round-button', RoundButton);
Vue.component('link-add', LinkAdd);
Vue.component('contact-add', ContactAdd);
Vue.component('hash-update', HashUpdate);
Vue.component('topic-suggest', TopicSuggest);
Vue.component('vote-element', VoteElement);
Vue.component('vote-element-multiple', VoteElementMultiple);
Vue.component('vote-group', VoteGroup);
Vue.component('request-access', RequestAccess);
Vue.component('person-line-summary', PersonLineSummary);
Vue.component('person-summary', PersonSummary);
Vue.component('quote-arrow', QuoteArrow);
Vue.component('quote-block', QuoteBlock);
Vue.component('candidate-stats', CandidateStats);
Vue.component('view-switch', ViewSwitch);
Vue.component('grid-or-list', GridOrList);
Vue.component('answer-block', AnswerBlock);
Vue.component('faq-block', FaqBlock);
Vue.component('history-town', HistoryTown);
Vue.component('mandates-calc', MandatesCalc);
Vue.component('mayor-item', MayorItem);
