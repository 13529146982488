const mutations = {
  demo () {}
};

mutations.fetchPartyList = function (state, payload) {
  payload.list.forEach(party => {
    if (!state.dynamic.partyList.find(p => p.reg === party.reg)) {
      state.dynamic.partyList.push(party);
    }
  });
}

mutations.fetchSource = function (state, payload) {
  var lookup = state.dynamic.source.find(s => s.source === payload.source);

  if (lookup) {
    // console.log(lookup.source, 'already in store');
  } else {
    state.dynamic.source.push({
      source: payload.source,
      content: payload.content
    });
  }
}

mutations.pdv = function (state, payload) {
  var lookup = state.dynamic.pdv.find(s => s.request === payload.request);

  if (lookup) {
    // console.log(lookup.request, 'already in store:', lookup.added);
  } else {
    state.dynamic.pdv.push({
      request: payload.request,
      added: payload.antiCache,
      content: payload.content
    });
  }
}

mutations.auth = function (state, payload) {
  state.auth = payload;
}

mutations.voteInit = function (state, payload) {
  var town = null;
  var obvod = null;

  state.voting.token = payload.token;

  payload.votes.forEach(x => {
    state.voting.votes.push(x);

    if (x.hash.split('-')[0].length < 6) {
      obvod = x.hash;
    } else if (x.hash != 'generic') {
      town = x.hash;
    }
  });

  state.voting.home.town = town;
  state.voting.home.obvod = obvod;
}

mutations.voteAdd = function (state, payload) {
  if (payload.hash.split('-')[0].length < 6) {
    state.voting.home.obvod = payload.hash;
  } else if (payload.hash != 'generic') {
    state.voting.home.town = payload.hash;
  }

  state.voting.votes.push(payload);
}

mutations.preferredView = function (state, payload) {
  state.personal.preferredView = payload;
}

export default mutations;
