import {csuColor} from '@/common/helpers';

export default {
	name: 'candidate-link-with-dot',
	props: ['data'],
	computed: {
		link: function () {
			var segments = [];

			segments.push('/obec');
			segments.push(this.$store.getters.town(this.data.csu.main.num).hash);
			segments.push(this.data.csu.main.hash);

			return [80,90,99].indexOf(this.reg) === -1 ? segments.join('/') : undefined;
		},
		color: function () {
			return csuColor(this.data);
		}
	}
};
