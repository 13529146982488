import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'link-add',
	props: ['hash', 'name', 'type', 'senate', 'phash'],
	components: {},
	data: function () {
		return {
			url: '',
			label: null,
			typeSelected: null,
			typeSocial: 0,
			author: null,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		},
		typeDetected: function () {
			var type = 'link';
			// var possibleSocialLink = false;

			if (this.url.split('twitter.com').length > 1) {
				type = 'tw';
				// possibleSocialLink = true;
			}

			if (this.url.split('facebook.com').length > 1) {
				type = 'fb';
				// possibleSocialLink = true;
			}

			if (this.url.split('youtube.com').length > 1) {
				type = 'yt';
				// possibleSocialLink = true;
			}

			if (this.url.split('linkedin.com').length > 1) {
				type = 'linkedin';
				// possibleSocialLink = true;
			}

			if (this.url.split('instagram.com').length > 1) {
				type = 'ig';
				// possibleSocialLink = true;
			}

			if (this.url.split('wikipedia.org').length > 1) {
				type = 'wiki';
				// possibleSocialLink = true;
			}

			// console.log(type);

			if (this.type) {
				type = this.type;
			}

			// console.log(type);

			if (['tw', 'fb', 'yt', 'ig', 'linkedin'].indexOf(type) > -1 && ['program','people','web'].indexOf(this.typeSelected) === -1) {
				this.typeSelected = 'social';
			} else if (type === 'wiki') {
				this.typeSelected = 'wiki';
			} else if (this.typeSelected) {
				type = this.typeSelected;
			}

			// console.log(type);

			if (type === 'link') {
				if (this.typeSelected === 'program') {
					type = 'program';
				} else if (this.typeSelected === 'people') {
					type = 'people';
				} else if (this.typeSelected === 'web') {
					type = 'web';
				} else if (this.typeSelected === 'wiki') {
					type = 'wiki';
				} else {
					this.typeSelected = 'link';
				}
			}

			// console.log(type);

			return type;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/addLink', {
					hash: this.hash,
					phash: this.phash,
					type: this.senate ? 'senate' : 'primator',
					url: encodeURIComponent(this.url),
					urlType: this.typeDetected,
					label: this.label,
          pass: this.$store.state.auth
				}).then(() => {
					this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 1500);
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.label = '';
			this.typeSelected = 'link';
		}
	},
	mounted: function () {
		if (this.type) {
			this.typeSelected = this.type;
		}
	}
};
