import {beautifyNumber} from '@/common/helpers'

export default {
	name: 'layout-parties',
	computed: {
		list: function () {
			var arr = [
				{label: 'Vybrané strany', list: this.$store.getters.parties().filter(x => x.level > 3), open: true},
				{label: 'Další strany a hnutí', list: this.$store.getters.parties().filter(x => x.level === 3)},
				{label: 'Koalice', list: this.$store.getters.parties().filter(x => x.level === 2)},
				{label: 'Sdružení', list: this.$store.getters.parties().filter(x => x.level === 1)}
			];

			return arr;
		},
		parties: function () {
			var list = [];
			var pts = this.$store.getters.parties();

			if (pts) pts.filter(x => x.level === 5).forEach(x => list.push(x));

			list.sort((a, b) => b.el_nominees - a.el_nominees);

			return list;
		}
	},
	methods: {
		beautifyNumber,
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
		this.$store.dispatch("ga", {title: "Strany a hnutí v komunálních a senátních volbách 2022"});
  }
};
