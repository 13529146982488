import {beautifyNumber} from '@/common/helpers';

export default {
	name: 'population-diff-graph',
	props: ['data'],
	components: {},
	data: function () {
		return {}
	},
	computed: {
		low: function () {
			var value = 0;
			this.data.list.forEach(item => {
				if (value > item.population.diff) value = item.population.diff;
			})
			return Math.round(100 * value / this.data.diff.maxY2Ydiff);
		},
		high: function () {
			var value = 0;
			this.data.list.forEach(item => {
				if (value < item.population.diff) value = item.population.diff;
			})
			return Math.round(100 * value / this.data.diff.maxY2Ydiff);
		}
	},
	methods: {
		beautifyNumber
	}
};
