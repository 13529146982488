import copy from 'copy-to-clipboard';
import {betterURL, truncate} from "@/common/helpers";

export default {
	name: 'admin-list-communal',
	props: ['csu', 'access', 'type'],
	data: function () {
		return {
			limit: 50,
			querySearchTown: '',
			copied: false
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		list: function () {
			var list = [];

			if (this.csu.length <= this.limit) {
				this.csu.forEach(party => {
					list.push({
						party,
						town: this.$store.getters.town(party.num)
					})
				});

				list.sort((a, b) => a.town.data[6].localeCompare(b.town.data[6], 'cs'));
			}

			return list;
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			// console.log(hash);

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.$store.getters.town(554499);
				if (hash === 'es') c = this.$store.getters.town(561754);

				if (c) {
					var o = {};
					o.num = c.data[0];
					o.display = c.data[6];
					o.link = '/obec/' + o.num + '-' + betterURL(o.display);
					o.okres = c.district;

					results.push(o);
				}
			}

			if (hash.length > 2) {
				var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

				Object.keys(townlist).forEach(key => {
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						var o = {};
						o.num = item[0];
						o.display = item[6];
						o.link = 'obec/' + o.num + '-' + betterURL(o.display);
						o.okres = this.$store.getters.district(key);
						o.size = item[4];

						if (item[7]) {
							o.part = this.$store.getters.town(item[7]);
						}

						results.push(o);
					});
				});

			}

			results.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		}
	},
	methods: {
		betterURL, truncate,
		tokenLink: function (hash) {
			return 'https://2022.programydovoleb.cz/admin/' + this.access.find(x => x.hash === hash).token;
		},
		copyLink: function (path) {

				copy(path);
				this.copied = true;

				this.$store.dispatch("ge", {
					action: "copy",
					category: "share",
					label: this.$route.fullPath
				});

			setTimeout(() => this.copied = false, 2000);
		}
	}
};
