import {beautifyNumber, betterURL, date, truncate, getLinkType} from "@/common/helpers";

export default {
	name: 'PersonLineSummary',
	props: ['person', 'custom', 'town', 'support', 'color', 'route', 'coalition'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		photo: function () {
			return this.custom.photos.find(x => x.phash === this.person[1]);
		},
		photoLink: function () {
			var link = 'https://data.programydovoleb.cz/obecne/strany/loga/missing.png';
			var photo = this.photo;
			var party = this.$(this.person[10]);

			if (photo) {
				link = photo.url;
			} else if (party && party.logo) {
				link = party.logo;
			}

			return link;
		},
		member: function () {
			if (this.photo && this.person[10] != 99) {
				if (this.coalition.length === 1 && this.person[10] === this.coalition[0]) {
					return undefined;
				} else {
					var p = this.$store.getters.party(this.person[10]);

					if (p && p.logo) {
						return p.logo != this.photoLink ? p : undefined;
					} else {
						return undefined;
					}
				}
			} else {
				return undefined;
			}
			// return this.$store.getters.party(this.person[10])
		},
		nominee: function () {
			if (this.coalition.length > 1 && (!this.member || this.person[11] != this.person[10])) {
				if (this.support && this.support.length > 0 && this.person[11] === 80) {
					return this.support[0];
				} else {
					var p = this.$store.getters.party(this.person[11]);

					if (p && p.logo) {
						return p.logo != this.photoLink ? p : undefined;
					} else {
						return undefined;
					}
				}
				// return this.support && this.support.length > 0 && this.person[11] === 80 ? this.support[0] : this.$store.getters.party(this.person[11])
			} else {
				return undefined;
			}
		},
		links: function () {
			var list = [];

			var prep = [];

			if (this.custom && this.custom.links) {

				this.custom.links.forEach(link => {
					if (link.phash === this.person[1]) {
						prep.push({
							href: link.url,
							type: link.type
						});
					}
				});

				var tw = prep.find(x => x.type === 'tw');
				var fb = prep.find(x => x.type === 'fb');
				var ig = prep.find(x => x.type === 'ig');
				var wiki = prep.find(x => x.type === 'wiki');

				if (tw) list.push(tw);
				if (fb) list.push(fb);
				if (ig) list.push(ig);
				if (wiki) list.push(wiki);
			}

			return list;
		},
		audio: function () {
			var test = false;

			return test;
		},
		home: function () {
			var t = null;

			if (this.person[9] && typeof this.person[9] === "number") {
				t = this.$store.getters.town(this.person[9])
			} else if (this.person[9] && typeof this.person[9] === "string") {
				t = this.person[9];
			} else {
				t = this.town;
			}

			return t;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		truncate,
		getLinkType
	}
};
