export default [
  {id:  1, display: "Jan Horník", club: "stan", party: 166, links: ["http://www.janhornik.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=169"]},
  {id:  4, display: "Alena Dernerová", club: "stan", party: 99, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=233"]},
  {id:  7, display: "Václav Chaloupek", club: "stan", party: 1022, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=294"]},
  {id: 10, display: "Tomáš Jirsa", club: "odstop", party: 53, links: ["http://www.tomasjirsa.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=171"]},
  {id: 13, display: "Jaroslav Větrovský", club: "pro", party: 99, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=295"]},
  {id: 16, display: "Jiří Oberfalzer", club: "odstop", party: 53, links: ["http://www.jirioberfalzer.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=172"]},
  {id: 19, display: "Ladislav Kos", club: "senpir", party: 195, links: ["http://www.ladislavkos.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=296"]},
  {id: 22, display: "Renata Chmelová", club: "kdu", party: 99, links: ["http://www.renatachmelova.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=297"]},
  {id: 25, display: "Jiří Růžička", club: "stan", party: 99, links: ["http://www.jiri-ruzicka.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=298"]},
  {id: 28, display: "Petr Holeček", club: "stan", party: 99, links: ["http://petr-holecek.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=299"]},
  {id: 31, display: "Jaroslav Doubrava", club: "non", party: 704, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=92"]},
  {id: 34, display: "Michael Canov", club: "stan", party: 703, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=300"]},
  {id: 37, display: "Tomáš Czernin", club: "odstop", party: 721, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=301"]},
  {id: 40, display: "Jaromír Strnad", club: "pro", party: 7, links: ["http://www.jaromirstrnad.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=239"]},
  {id: 43, display: "Miluše Horská", club: "kdu", party: 99, links: ["http://www.milusehorska.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=240"]},
  {id: 46, display: "Petr Šilar", club: "kdu", party: 1, links: ["http://www.petr-silar.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=241"]},
  {id: 49, display: "Jaromíra Vítková", club: "kdu", party: 1, links: ["http://www.jaromiravitkova.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=302"]},
  {id: 52, display: "Miloš Vystrčil", club: "odstop", party: 53, links: ["http://www.vystrcil.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=243"]},
  {id: 55, display: "Jan Žaloudík", club: "pro", party: 99, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=244"]},
  {id: 58, display: "Jiří Dušek", club: "pro", party: 99, links: ["http://www.rozumdosenatu.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=303"]},
  {id: 61, display: "Lumír Kantor", club: "kdu", party: 99, links: ["http://www.lumirkantor.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=304"]},
  {id: 64, display: "Ladislav Václavec", club: "pro", party: 99, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=305"]},
  {id: 67, display: "Petr Orel", club: "senpir", party: 5, links: ["http://www.petr-orel.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=306"]},
  {id: 70, display: "Zdeněk Nytra", club: "odstop", party: 53, links: ["http://www.zdeneknytra.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=307"]},
  {id: 73, display: "Jiří Cieńciała", club: "pro", party: 99, links: ["https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=308"]},
  {id: 76, display: "Šárka Jelínková", club: "kdu", party: 1, links: ["https://sarkajelinkova.eu/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=309"]},
  {id: 79, display: "Anna Hubáčková", club: "kdu", party: 99, links: ["http://www.annahubackova.cz/", "https://www.senat.cz/senatori/index.php?lng=cz&O=13&par_3=310"]}
]
