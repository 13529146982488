import axios from 'axios';
import {truncate, date} from '@/common/helpers'

export default {
	name: 'admin-list-tips',
	props: ['list'],
	data: function () {
		return {
			done: []
		}
	},
	computed: {
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		truncate, date,
		act: function (item, status) {
			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/tips-approve', {
				id: item.id,
				table: 'links',
				label: item.label,
				type: item.type,
				hash: item.hash,
				status: status || -1,
				token: this.token,
				pass: this.$store.state.auth
			}).then(() => {
				this.done.push(item);
			});
		}
	}
};
