import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'admin-about',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			about: null,
			url: null,
			storedAbout: null,
			storedURL: null,
			id: null,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return ((this.url && isValidHttpUrl(this.url)) || (!this.url && this.about));
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/about-update', {
					hash: this.hash,
					phash: this.phash,
					url: encodeURIComponent(this.url),
					id: this.id,
					about: this.about ? this.about.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
          token: this.token
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.storedURL  = this.url;
					this.storedAbout = this.about;

					this.id = response.data.id;
				});
			}
		},
		remove: function () {
			axios.post(this.$store.state.api + 'admin/about-remove', {
				hash: this.hash,
				phash: this.phash,
				id: this.id,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					this.storedURL  = null;
					this.storedAbout = null;
					this.id = null;
				}
			})
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				this.storedURL = src.url;
				this.storedAbout = src.txt;
				this.url = src.url != 'null' ? src.url : null;
				this.about = src.txt;
				this.id = src.id;
			})
		}
	}
};
