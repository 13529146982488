import {csuColor, links, truncate, betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType} from '@/common/helpers';

export default {
	name: 'layout-party',
	props: ['id'],
	data: function () {
		return {
			compact: true,
			querySearchTown: null,
			querySearchTownSearching: false,
			cooperationLimits: {
				koalice: true,
				sdruzeni: true
			}
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		reg: function () {
			return Number(this.id.split('-')[0]);
		},
		party: function () {
			var party = this.$store.getters.party(this.reg);

			this.$store.dispatch("ga", {title: party.short + " v komunálních a senátních volbách 2022"});

			return party;
		},
		color: function () {
			var c = '#aaa';

			if (this.data) {
				if (this.data.csu.color) {
					c = this.data.csu.color;
				} else if (this.data.csu.coalition) {
					c = gradient(this.data.csu);
				}
			}

			return c;
		},
		data: function () {
			return this.$store.getters.pdv('party/fetch/' + this.reg);
		},
		senateList: function () {
			var list = [];

			this.$store.state.static.senate.list.forEach(tem => list.push(tem));

			list.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

			return list;
		},
		news: function () {
			var list = [];

			if (this.data) list = this.data.news;

			return list;
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.$store.getters.town(554499);
				if (hash === 'es') c = this.$store.getters.town(561754);

				if (c) {
					c.active = this.data && this.data.custom.activity && this.data.custom.activity.towns.indexOf(c.data[0]) > -1;
					results.push(this.$store.getters.town(c));
				}
			}

			if (hash.length > 2) {
				var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

				Object.keys(townlist).forEach(key => {
					// console.log(key);
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						results.push({
							data: item,
							district: this.$store.getters.district(key),
							hash: item[0] + '-' + betterURL(item[6]),
							part: item[7] ? this.$store.getters.town(item[7]) : null,
							active: this.data && this.data.custom.activity && this.data.custom.activity.towns.indexOf(item[0]) > -1
						});
					});
				});

			}

			results.sort((a, b) => a.data[6].localeCompare(b.data[6], 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		},
		links: function () {
			var list = [];

			if (this.data && this.data.custom.links) {
				list = links(this.data.custom.links);
			}

			return list;
		},
		cooperation: function () {
			var towns = [];
			var senate = [];
			var sdruzeni = [];
			var koalice = [];
			var sdruzeniNK = 0;
			var sdruzeniNKObject = null;
			var alone = 0;

			if (this.data) {
				Object.keys(this.data.cooperation.towns).forEach(key => {
					var id = Number(key.split('_')[1]);

					if (id != 80) {
						towns.push({
							party: this.$store.getters.party(id),
							value: this.data.cooperation.towns[key]
						});
					}
				});

				Object.keys(this.data.cooperation.senate).forEach(key => {
					senate.push({
						party: this.$store.getters.party(Number(key.split('_')[1])),
						value: this.data.cooperation.senate[key]
					});
				});

				towns.sort((a, b) => a.party.short.localeCompare(b.party.short, 'cs'));
				senate.sort((a, b) => a.party.short.localeCompare(b.party.short, 'cs'));

				towns.sort((a, b) => b.value - a.value);
				senate.sort((a, b) => b.value - a.value);
			}

			if (this.party && this.party.type < 3) {
				sdruzeni = this.$store.getters.parties().filter(x => x.type === 4 && x.coalition.length > 2 && x.coalition.find(y => y === this.reg));
				sdruzeniNKObject = this.$store.getters.parties().find(x => x.type === 4 && x.coalition.length === 2 && x.coalition.find(y => y === this.reg));

				if (sdruzeniNKObject) sdruzeniNK = sdruzeniNKObject.nom_towns;

				koalice = this.$store.getters.parties().filter(x => x.type === 3 && x.coalition.find(y => y === this.reg));

				sdruzeni.sort((a, b) => b.nom_towns - a.nom_towns);
				koalice.sort((a, b) => b.nom_towns - a.nom_towns);


			}

			return {towns, senate, sdruzeni, koalice, sdruzeniNK, sdruzeniNKObject, alone};
		},
		mayor: function () {
			var list = {
				towns: [],
				others: [],
				parts: [],
				all: []
			};

			var used = [];
			var t = null;
			var tl = 'towns';

			if (this.data && this.data.mayor) {
				this.data.mayor.forEach(x => {
					if (used.indexOf(x.num) === -1) {
						used.push(x.num);

						t = this.$store.getters.town(x.num);

						tl = 'towns';
						if (t.data[4] === 5) tl = 'parts';
						if (t.data[4] === 1) tl = 'others';

						list[tl].push({
							mayor: x,
							town: t
						})

						list.all.push({
							mayor: x,
							town: {
								point: t.data,
								data: {color: this.color}
							}
						})
					}
				})

				list.towns.sort((a, b) => a.town.data[6].localeCompare(b.town.data[6], 'cs'));
				list.others.sort((a, b) => a.town.data[6].localeCompare(b.town.data[6], 'cs'));
				list.parts.sort((a, b) => a.town.data[6].localeCompare(b.town.data[6], 'cs'));
			}

			return list;
		},
		activity: function () {
			var list = [];

			if (this.data && this.data.custom && this.data.custom.activity && this.data.custom.activity.towns) {
				this.data.custom.activity.towns.forEach(item => {
					list.push({
						point: this.$store.getters.town(item).data,
						data: {color: this.data.custom.activity_result.towns.indexOf(item) > -1 ? this.color : '#ddd'}
					})
				});
			}

			return list;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, truncate,
		sort: function (list) {
			var arr = [];

			list.forEach(x => arr.push(x));

			arr.sort((a, b) => a[6].localeCompare(b[6], 'cs'));

			return arr;
		},
		level: function (item, senate) {
			var s = "nekandidují";

			if (item && !senate) {
				if (item.level.members) s = "na kandidátce";
				if (item.level.withSNK) s = "s nezávislými";
				if (item.level.coalition) s = "v koalici";
				if (item.level.alone) s = "samostatně";
			}

			if (item && senate) {
				if (item.level.support) s = "doporučení";
				if (item.level.coalition) s = "koaliční podpora";
				if (item.level.nominee) s = "nominace";
				if (item.level.member) s = "členství";
			}

			return s;
		},
		sortedTowns: function (towns) {
			var list = [];

			towns.forEach(town => {
				var item = this.$store.getters.town(town);
				list.push({num: item.data[0], display: item.data[6]});
			})

			list.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

			return list;
		},
		getParty: function (item) {

			console.log(this.$store.getters.party(item.party))

			var o = {
				display: item.display,
				color: this.colorOfParty(item.party),
				party: this.$store.getters.party(item.party) || JSON.parse(item.party)
			}


			return o;
		},
		getInTown: function (num) {
			var res = {
				label: 'doplnit',
				type: 0,
				data: null
			};

			if (this.data.primator) {
				var partiesInTown = this.data.primator.list.filter(x => x.town_num === num);

				partiesInTown.forEach(party => {
					if (typeof party.party === 'number') {
						if (party.party === this.reg) {
							res.label = 'samostatně';
							res.type = 1;
							res.data = party;
							res.data.party2 = party.party;
						}
					}
					else if (typeof party.party === 'string') {
						var data = JSON.parse(party.party);

						if (data) {
							if (data.coalition) {
								var member = data.coalition.find(x => x === this.reg);

								if (member) {
									res.label = 'v koalici';
									res.label2 = data.short;
									res.type = 2;
									res.data = party;
									res.data.party2 = data;
								}
							}
						}
					}
				});
			}

			return res;
		},
		getInObvod: function (region) {
			var res = {
				label: 'doplnit',
				data: null
			};

			if (this.data.senate) {
				var candidatesInObvod = this.data.senate.list.filter(x => x.region === region);

				candidatesInObvod.forEach(cand => {
					if (cand.party === this.reg) {
						res.label = 'nominace';
						res.data = cand;
						res.data.party2 = cand.party;
					} else {
						if (cand.support) {
							var s = JSON.parse("[" + cand.support + "]");

							var member = s.find(x => x === this.reg);

							if (member) {
								res.label = 'doporučení';
								res.data = cand;
								res.data.party2 = cand.party;
							}
						}
					}
				});
			}

			return res;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		},
		csuColor
  },
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		id: function () {
			this.tick++;
			window.scrollTo(0, 1);
		}
	}
};
