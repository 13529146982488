import ResultsGraph from '@/components/results/main/results-element/do';
import {betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType, csuColor} from '@/common/helpers';

export default {
	name: 'layout-obvod',
	props: ['id'],
	data: function () {
		return {}
	},
  components: {
		ResultsGraph
	},
	computed: {
		loaded: function () {
			return true;
		},
		analytics: function () {
			return this.$store.getters.pdv('system/analytics/obvod*' + this.id);
		},
		num: function () {
			return Number(this.id.split('-')[0]);
		},
		data: function () {
			return this.$store.getters.pdv('senate/fetch/' + this.num);
		},
		about: function () {
			return this.data ? this.data.about : null;
		},
		overview: function () {
			if (!this.data || this.loaded === false) return;

			var dis = this.$store.state.static.senate.list.find(x => x.id === this.num);

			var obj = {
				about: {
					id: dis.id,
					name: dis.display,
					hash: dis.id + '-' + betterURL(dis.display),
					dates: []
				},
				current: this.$store.state.static.senate.current.find(x => x.id === dis.id),
				list: [],
				hash: betterURL((this.$store.state.static.senate.current.find(x => x.id === dis.id).display))
			}

			obj.current.club2 = this.$store.state.static.senate.clubs.find(x => x.hash === obj.current.club);
			obj.current.party2 = this.$(obj.current.party);

			this.data.list.forEach(item => {
				var o = {
					status: 1,
					display: item.csu.main.cand_name + ' ' + item.csu.main.cand_family,
					item,
					color: this.colorOfParty(item.csu.main.party),
					party: this.$(item.csu.main.party) || {short: item.csu.main.party, color: '#ccc'}
				}

				obj.list.push(o);
			});

			this.$store.state.static.senate.volby.dates.forEach(date => {
				var o = date.obvod.find(d => d === this.num);

				if (o) obj.about.dates.push(date.date);
			});

			obj.list.sort((a, b) => a.display.split(' ')[a.display.split(' ').length - 1].localeCompare(b.display.split(' ')[a.display.split(' ').length - 1], 'cs'));
			// obj.list.sort((a, b) => (a.hash.split('-').pop() || '').split(' ').pop().localeCompare((b.hash.split('-').pop() || '').split(' ').pop(), 'cs'))

			// if (item) {
				this.$store.dispatch("ga", {title: 'Senátní obvod ' + obj.about.name + " (" + this.num + ") v senátních volbách 2022"});
			// }

			return obj;
		},
		news: function () {
			var list = [];

			if (this.data && this.data.news.length > 0) {
				this.data.news.forEach(x => {
					if (list.find(y => y.url === x.url && y.title === x.title)) {
						// console.log(x);
					} else {
						list.push(x)
					}
				});
			}

			if (this.senate && this.senate.news.length > 0) {
				this.senate.news.forEach(x => {
					if (list.find(y => y.url === x.url && y.title === x.title)) {
						// console.log(x);
					} else {
						list.push(x)
					}
				});
			}

			list.sort((a, b) => b.date.localeCompare(a.date, 'cs'));

			return list;
		},
		elections: function () {
			var data = this.$store.getters.getSource('souhrny/obvody/' + this.num);
			var latest;

			if (data) {
				data.elections.sort((a, b) => b.date - a.date);
				latest = data.elections[0];
			}

			return latest;
		},
		towns: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/obce-flat') || {list: {}};
		},
		townsInObvod: function () {
			var list = [];

			if (this.about) {
				this.about.list.forEach(num => {
					list.push({point: this.$store.getters.town(num).data});
				})
			}

			return list;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, csuColor,
		sort: function (list) {
			var arr = [];

			list.forEach(x => arr.push(x));

			arr.sort((a, b) => a[6].localeCompare(b[6], 'cs'));

			return arr;
		},
		support: function (data) {
			var list = [];
			var json = JSON.parse("[" + data + "]");

			json.forEach(member => {
				if (typeof member === 'number') {
					list.push(this.$store.getters.party(member))
				} else {
					list.push({
						name: member,
						short: member,
						color: colorOfParty(null, member)
					})
				}
			});

			return list;
		},
		activeSenate: function (reg) {
			var obj = {
				icon: 'none',
				link: null,
				label: 'nikoho'
			}

			if (this.data && this.data.list) {
				this.data.list.forEach(member => {
					if (member.csu.main.coalition.find(x => x === reg) || member.csu.main.member === reg || member.csu.main.nominee === reg || member.custom.support.find(x => x.reg && x.reg === reg)) {
						obj.link = '/obvod/' + this.num + '-' + betterURL(this.data.about.polist.name) + '/' + member.csu.main.hash;
						obj.icon = 'check';
						obj.label = member.csu.main.cand_name[0] + '. ' + member.csu.main.cand_family;
					}
				});
			}

			return obj;
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		id: function () {
			this.tick++;
			window.scrollTo(0, 1);
		}
	}
};
