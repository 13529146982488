import axios from 'axios';

/* eslint-disable */

export default {
	name: 'update-form',
	props: ['data', 'hint', 'compact', 'hash'],
	components: {},
	data: function () {
		return {
			url: '',
			note: '',
			name: '',
			sent: false,
			sending: false,
			response: ''
		}
	},
	computed: {
		valid: function () {
			return this.note.length > 0;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=report/add', {
					// component: {
					component: this.data,
					page: this.$route.fullPath,
					// },
					// tip: {
					source: encodeURIComponent(this.url),
					content: encodeURIComponent(this.note),
					name: encodeURIComponent((this.hash ? this.hash + ': ' : '') + this.name)
					// }
				}).then(response => {
					this.sending = false;
					this.sent = true;
					this.response = response;
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.note = '';
			this.name = '';
		}
	},
	mounted: function () {
		this.note = '';
		this.url  = '';
		this.name = '';
	}
};
