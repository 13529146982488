import {date} from '@/common/helpers';

export default {
	name: 'layout-news',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		news: function () {
			return this.$store.getters.pdv('tip/news/9999', 2022);
		},
		news_sorted: function () {
			if (!this.news) return undefined;

			var list = [
				{id: '2022', months: [
					{name: "Říjen", list: []},
					{name: "Září", list: []},
					{name: "Srpen", list: []},
					{name: "Červenec", list: []},
					{name: "Červen", list: []},
					{name: "Květen", list: []},
					{name: "Duben", list: []},
					{name: "Březen", list: []},
					{name: "Únor", list: []},
					{name: "Leden", list: []}
				]},
				{id: '2021', months: [
					{name: "Starší", list: []}
				]}
			];

			this.news.list.forEach(item => {

				if (item.type === "senate" || item.type === "primator" || item.type === "program" || item.type === "answers") {
					var date = item.date.split('-');

					if (date[0] != '2022') {
						list[1].months[0].list.push(item);
					} else {
						list[0].months[10 - Number(date[1])].list.push(item);
					}
				}
			})

			return list;
		},
		newest: function () {
			if (!this.news) return undefined;

			var list = [];
			var days = [];
			var cando = true;

			this.news.list.forEach(item => {
				if (cando) {
					if (!days.find(x => x === item.date)) {
						days.push(item.date);
					}

					if ((days.length < 4 || list.length < 5)) {
						list.push(item);
					} else {
						cando = false;
					}
				}
			});

			console.log(days);

			return list;
		}
	},
  methods: {
		date
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {path: "", title: "Archiv novinek"});
  }
};
