import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'main-header',
	props: ['logo', 'name', 'tipsPrimator', 'placeholder'],
	data: function () {
		return {
			pass: null,
			showMenu: false,
			showMenu2: false,
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.$store.getters.town(554499);
				if (hash === 'es') c = this.$store.getters.town(561754);

				if (c) {
					results.push(this.$store.getters.town(c));
				}
			}

			if (hash.length > 2) {
				var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

				Object.keys(townlist).forEach(key => {
					// console.log(key);
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						results.push({
							data: item,
							district: this.$store.getters.district(key),
							part: item[7] ? this.$store.getters.town(item[7]) : null,
							hash: item[0] + '-' + betterURL(item[6])
						});
					});
				});

			}

			results.sort((a, b) => a.data[6].localeCompare(b.data[6], 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		},
	},
	watch:{
    $route (){
        this.showMenu = false;
    }
	},
	methods: {
		authme: function () {

			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/login', {
				pass: this.pass
			}).then((response) => {
				if (response.data.result === 1) {
					this.$store.commit('auth', this.pass);
					this.$refs.authform.opened = false;
					this.showMenu = false;
				}
			});
		}
	},
	mounted: function () {
		// if (this.$route.query.dnt) {
		// 	this.$refs.authform.opened = true;
		// }
	}
};
