import {links, stripURLintoDomain, csuColor, joinWithLast, betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType, isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-election-candidate',
	props: ['obvodHash','hash'],
	data: function () {
		return {}
	},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		analytics: function () {
			return this.$store.getters.pdv('system/analytics/obvod*' + this.obvodHash + '*' + this.hash);
		},
		data: function () {
			var loaded = this.$store.getters.pdv('detail/senate/' + this.hash);

			return loaded ? loaded.data : null;
		},
		obvod: function () {
			return this.$store.state.static.senate.list.find(x => x.id === Number(this.obvodHash.split('-')[0]));
		},
		name: function () {

			if (this.data) {
				this.$store.dispatch("ga", {title: this.data.csu.main.cand_name + ' ' + this.data.csu.main.cand_family + ", " + this.obvod.display + " v senátních volbách 2022"});
				return this.data.csu.main.cand_name + ' ' + this.data.csu.main.cand_family;
			} else {
				return null;
			}
		},
		namefull: function () {
			return this.data ? [this.data.csu.main.cand_before, this.data.csu.main.cand_name, this.data.csu.main.cand_family, this.data.csu.main.cand_after] : null;
		},
		member: function () {
			return this.data ? this.$store.getters.party(this.data.csu.main.member) : null;
		},
		nominee: function () {
			return this.data ? this.$store.getters.party(this.data.csu.main.nominee) : null;
		},
		party: function () {
			return this.data ? this.$store.getters.party(this.data.csu.main.party) : null;
		},
		coalition: function () {
			return this.data ? this.$store.getters.party(this.data.csu.main.coalition) : null;
		},
		links: function () {
			return this.data ? links(this.data.custom.links) : null;
		},
		contacts: function () {
			return this.data ? this.data.custom.contacts : null;
		},
		web: function () {
			return this.data ? this.links.find(x => x.type === 'web') : null;
		},
		program: function () {
			return this.data ? this.links.find(x => x.type === 'program') : null;
		},
		support: function () {
			return this.data ? this.data.custom.support : null;
		},
		mobile: function () {
			return window.innerWidth < 800;
		},
		color: function () {
			return this.data ? csuColor(this.data) : null;
		},
		leader: function () {
			var data = this.data;
			var item = null;

			if (data) {
				item = {
					name: this.namefull,
					work: data.csu.main.work,
					about: data.custom.about.find(x => x.hash === data.csu.main.hash),
					photo: data.custom.photo.find(x => x.hash === data.csu.main.hash),
					links: data.custom.links.filter(x => x.hash === data.csu.main.hash),
					motto: [], // data.custom.motto.filter(x => x.hash === data.csu.main.hash),
					data: [
						data.csu.main.id,
						data.csu.main.hash,
						data.csu.main.cand_name,
						data.csu.main.cand_family,
						data.csu.main.cand_before,
						data.csu.main.cand_after,
						data.csu.main.age,
						data.csu.main.sex,
						data.csu.main.work,
						data.csu.main.town,
						data.csu.main.member,
						data.csu.main.nominee,
						null
					]
				}
			}

			return item;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		joinWithLast, betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, isValidHttpUrl, csuColor,
		domain: function (url) {
			return stripURLintoDomain(url).split('/')[0]
		},
		mediaicon: function (url) {
			var outlets = ['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'];
			var link = this.$store.state.root + 'static/icon/link.svg';

			outlets.forEach(o => {
				if (url.split(o).length > 1) link = this.$store.state.root + 'static/icon-media/' + o + '.jpg';
			})

			return link;
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
		setTimeout(() => {
			this.$refs.anchors.trigger(location.hash);
		}, 1500);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		},
		$route: function () {
			window.scrollTo(0, 1);
		}
	}
};
