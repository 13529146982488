import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-prognostic-form',
	data: function () {
		return {
			parties: [
				{reg: 768, value: 1684, selected: null},
				{reg: 7, value: 1946, selected: null},
				{reg: 1, value: 3646, selected: null},
				{reg: 47, value: 1471, selected: null},
				{reg: 53, value: 2600, selected: null},
				{reg: 720, value: 358, selected: null},
				{reg: 703, value: 148, selected: null},
				{reg: 1114, value: 141, selected: null},
				{reg: 166, value: 2601, selected: null},
				{reg: 714, value: 84, selected: null},
				{reg: 721, value: 484, selected: null},
				{reg: 5, value: 186, selected: null},
			],
			labels: [
				'bez odpovědi',
				'drtivé zhoršení',
				'výrazné zhoršení',
				'zhoršení',
				'mírné zhoršení',
				'podobně jako minule',
				'mírné zlepšení',
				'zlepšení',
				'výrazné zlepšení',
				'dramatické zlepšení',
				'ještě více'
			],
			name: null,
			link: null,
			sending: false,
			sent: false,
			hash: null
		}
	},
	components: {},
	computed: {
		common: function () {
			return this.$store.getters.pdv('prognostic/common');
		},
		options: function () {
			var list = [];

			this.parties.forEach((party) => {
				var o = {
					party,
					limits: [0]
				}

				o.limits.push(Math.round(party.value * .15));
				o.limits.push(Math.round(party.value * .4));
				o.limits.push(Math.round(party.value * .75));
				o.limits.push(Math.round(party.value * .96));

				o.limits.push(Math.round(party.value * 1.05));
				o.limits.push(Math.round(party.value * 1.33));
				o.limits.push(Math.round(party.value * 1.8));
				o.limits.push(Math.round(party.value * 2.5));
				o.limits.push(Math.round(party.value * 5));

				o.limits.push(25000);

				list.push(o);
			});

			return list;
		},
		anySelected: function () {
			return this.parties.filter(x => x.selected).length > 0
		},
		linkFix: function () {
			var src = null;

			if (this.link) {
				if (this.link[0] === '@') src = "https://twitter.com/" + this.link;
				if (isValidHttpUrl(this.link[0] != '@' && this.link.split('.').length > 1 && 'https://' + this.link)) src = 'https://' + this.link;
				if (isValidHttpUrl(this.link)) src = this.link;
			}

			return src;
		},
		valid: function () {
			var res = true;

			if (!this.anySelected) res = false;
			if (this.link && !isValidHttpUrl(this.linkFix)) res = false;
			if (this.sending) res = false;

			return res;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				var values = [];

				this.parties.forEach(p => values.push(p));

				values.sort((a, b) => a.reg - b.reg);

				axios.post(this.$store.state.api + 'prognostic/add', {
					name: this.name,
					link: this.linkFix,
					values: values.map(x => x.selected)
				}).then((response) => {
					this.sending = false;

					if (response.data.code === 200) {
						this.sent = true;
						this.link = this.linkFix;

						this.hash = 'https://2022.programydovoleb.cz/prognosticka-hra/ticket/' + response.data.hash;
						window.scrollTo(0, 1);
					}
				});
			}
		},
		another: function () {
			this.sent = false;
			this.parties.forEach(p => p.selected = null);
		}
	},
	mounted: function () {
		window.scrollTo(0, 1);
		this.$store.dispatch("ga", {title: "Prognostická hra"});
	}
};
