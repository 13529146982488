import axios from 'axios';

export default {
	name: 'tip-form',
	props: ['id', 'hint', 'name'],
	components: {},
	data: function () {
		return {
			url: '',
			note: '',
			author: null,
			sent: false,
			sending: false,
			showHints: false
		}
	},
	computed: {
		valid: function () {
			return this.note.length > 3;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/addTopic', {
					town: this.hint,
					note: encodeURIComponent(this.note),
          pass: this.$store.state.auth
				}).then(() => {
					this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 1500);
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.note = '';
		}
	}
};
