import axios from 'axios';
import {betterURL, joinWithLast, isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-suggest-senate',
	data: function () {
		return {
			// form: {
				obvod: -1,
				name: null,
				work: null,
				party: -1,
				support: [],
				url: null,
				titleCustom: 0,
				title: null,
				note: null,
				author: null,
				pass: null,
				sent: false,
				sending: false,
				showHints: false,
				newsTypes: {
					fb: "facebook.com",
					tw: "twitter.com",
					yt: "youtube.com",
					ig: "instagram.com",
					linkedin: "linkedin.com",
					wiki: "wikipedia.com",
					blesk: "blesk.cz",
					denik: "denik.cz",
					drbna: "drbna.cz",
					hn: "hn.cz",
					idnes: "idnes.cz",
					irozhlas: "irozhlas.cz",
					lidovky: "lidovky.cz",
					novinky: "novinky.cz",
					seznamzpravy: "seznamzpravy.cz"
				},
				parties: {
					primary: this.$store.state.static.parties.primary,
					secondary: this.$store.state.static.parties.secondary
				}
			// }
		}
	},
  components: {},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		},
		demo: function () {

			var person = {};
			var news = {};
			var photo = [];
			var suggestionTitlePieces = [];
			var suggestionTitle = null;
      var relates = [];

			person.display = this.name;
			person.work = this.work;
			person.party = this.party;
			person.party2 = person.party;
			person.support = this.support.join(",");
			person.region = this.obvod > 0 ? this.obvod : 1;
			person.hash = betterURL(this.obvod + '-' + person.display);
      relates = [];

      if (this.party != -1 && this.party != 99) {
        relates.push(this.party);
      }

			news.hash = person.hash;
			news.url = this.url || "https://programydovoleb.cz";
			news.date = (new Date()).toISOString().split("T")[0];
			news.type = "senate";
			news.region = this.obvod;

			if (news.url.indexOf("http") === -1) news.url = "https://" + news.url;

			var domain = news.url.split('://')[1].split('/')[0];
			if (domain.indexOf('www.') > -1) domain = domain.split('www.')[1];

			news.outlet = domain;

			Object.keys(this.newsTypes).forEach(key => {
				if (news.url.indexOf(this.newsTypes[key]) > -1) news.outlet = key;
			});

			suggestionTitlePieces.push(this.name || 'Zpráva, že kandidát');

      if (person.party > 0) {
        suggestionTitlePieces.push("(" + this.$store.getters.party(person.party).short + ")");
      }

			if (person.work) {
				suggestionTitlePieces.push("- " + person.work + ' -');
			}

			if (person.region > 0) {
				suggestionTitlePieces.push("bude kandidovat v obvodě " + person.region + ' - ' + this.$store.state.static.senate.list.find(x => x.id === person.region).display);
			}

			if (this.support.length > 0) {
				var supporters = [];

				this.support.forEach(sup => {
					if (typeof sup === "number") {
						supporters.push(this.$store.getters.party(sup).short);
            relates.push(sup);
					} else {
						supporters.push(sup);
					}
				});

				suggestionTitlePieces.push("s podporou " + joinWithLast(supporters));
			}

			suggestionTitle = suggestionTitlePieces.join(" ");

			news.title = this.title || suggestionTitle;

      if (person.support === "") person.support = null;
      if (relates.length === 0) {
        person.relates = null;
      } else {
        person.relates = relates.join(",");
      }

			return {
				person, news, photo, suggestionTitle
			}
		}
	},
	methods: {
		betterURL,
		useSuggestedTitle: function () {
			this.title = null;
		},
		addSupport: function (id) {
			var sid = id || '"' + this.$refs.support.value + '"';

			if (!this.support.find(x => x === sid)) {
				this.support.push(sid);
				this.$refs.support.value = "";
			}
		},
		send: function () {
			if (this.valid && !this.sending) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/addSenate', {
					data: this.demo.person,
					news: this.demo.news,
					author: this.author,
          note: this.note,
          pass: this.$store.state.auth
				}).then(() => {
          window.scrollTo(0, 1);

          this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 1500);
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = null;
			this.note = null;
			this.name = null;
      this.party = -1;
      this.support = [];
      this.work = null;

      window.scrollTo(0, 1);
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Nový kandidát do Senátu"});

		if (this.$route.query && this.$route.query.obvod) {
			this.obvod = Number(this.$route.query.obvod);
		}
  }
};
