import {csuColor, truncate} from '@/common/helpers';
import copy from 'copy-to-clipboard';

export default {
	name: 'MandatesCalc',
	props: ['data', 'seats'],
	data: function () {
		return {
			parties: [],
			preview: false,
			max: 0,
			copied: false,
			anycalc: false
		}
	},
	computed: {
		check: function () {
			var sum = 0;

			this.parties.forEach(party => sum += Number(party.votes));

			return {valid: !(sum < 1 || sum > 100 || this.parties.find(x => Number(x.votes) < 0 || Number(x.votes) > 100)), remain: 100 - sum}
		}
	},
	methods: {
		dhondt: function () {
			var list = [];
			this.max = 0;
			this.anycalc = true;

			this.parties.forEach(party => {
				var votes = Number(party.votes);
				if (votes > this.max) this.max = votes;

				if (votes >= 5) {
					for (var i = 0; i < party.candidates; i++) {
						list.push({index: party.index, value: votes / (i + 1)})
					}
				}
			});

			list.sort((a, b) => b.value - a.value);

			list.splice(this.seats);

			this.parties.forEach(party => {
				party.mandates = list.filter(x => x.index === party.index).length;
				// party.list = list.filter(x => x.index === party.index)
			});

			console.log(list);
		},
		cancel: function () {
			this.data.forEach((party) => {
				party.votes = 0;
				party.mandates = 0;
			});
			this.preview = false;
		},
		copy: function () {
			copy(this.$store.state.root + this.$route.path.substring(1) + '?hlasy=' + this.parties.map(x => x.votes).join(','));
			this.copied = true;

			setTimeout(() => this.copied = false, 1500);
		}
	},
	mounted: function () {

		var preview = this.$route.query.hlasy ? this.$route.query.hlasy.split(',').map(x => Number(x)) : undefined;

		this.data.forEach((party, index) => {
			var obj = {
				index,
				name: truncate(party.csu.main.party_name, 6),
				color: csuColor(party),
				candidates: party.csu.main.candidates_count,
				votes: preview ? preview[index] : 0,
				mandates: 0
			}

			this.parties.push(obj);
		})

		if (preview) {
			this.dhondt();
			this.preview = true;
		}
	}
};
