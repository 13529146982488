import axios from 'axios';
import state from './state';

const server = state.server;
// const api = state.api;

const antiCache = 'c=' + Math.round(new Date().getTime() / (1000 * 60 * 60 * 4)) + 'r1';

const actions = {};

var page_visited = 0;
var page_last = "xxx";

actions.ga = function (context, payload) {

  document.title = payload.title + ' - Programy do voleb';

  payload.path = payload.path || context.state.route.fullPath.slice(1, context.state.route.fullPath.length);
  payload.path = payload.path.split('?')[0];

  if (page_last != payload.path) {

    page_last = payload.path;
    page_visited++;

    var api = state.api;

    if (payload.api) {
      api = api.split('2022').join(payload.api);
    }

    axios.post(api + 'system/log&c=' + (new Date().getTime()), {
      n: page_visited,
      t: payload.title,
      p: payload.path,
      m: ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 ) ? 1 : 0
    });
  }

  // if (window.gtag) {
  //   window.gtag('config', 'UA-4347750-19', {'page_path': payload.path, 'page_title': payload.title});
  // } else {
  //   console.log('GA:', '/' + payload.path, ' : ', payload.title);
  // }
};

actions.ge = function (context, payload) {
  // console.log(payload);

  axios.post(state.api + 'system/ev&c=' + (new Date().getTime()), {
    p: (payload.path || context.state.route.fullPath.slice(1, context.state.route.fullPath.length)).split('?')[0],
    e: payload.action + "-" + payload.category,
    v: payload.label
  });
};

var fetchPartyListLoadInitiated = false;

actions.fetchPartyList = function (context, payload) {
  if (fetchPartyListLoadInitiated === true) {
    if (payload && payload.onComplete) payload.onComplete();
  } else {
    fetchPartyListLoadInitiated = true;
    axios.get(server + 'obecne/strany.json').then(response => {
      context.commit('fetchPartyList', response.data);
      if (payload && payload.onComplete) payload.onComplete();
    });
  }
}

actions.fetchSource = function (context, payload) {
  var lookup = context.state.dynamic.source.find(item => item.source === payload.source);

  if (lookup) {
    // console.log('Town', lookup.name, 'is known');
    if (payload && payload.onComplete) payload.onComplete();
  } else {
    try {
      axios.get(server + payload.source + '.json?' + antiCache).then(response => {
        context.commit('fetchSource', {
          source: payload.source,
          content: response.data
        });

        if (payload && payload.onComplete) payload.onComplete();
      });
    } catch (e) {
      if (payload && payload.onError) payload.onError();
    }
  }
}

actions.pdv = function (context, payload) {
  var lookup = context.state.dynamic.pdv.find(item => item.request === payload.request);

  if (lookup) {
    if (payload && payload.onComplete) payload.onComplete();
  } else {
    try {

      var api = state.api;

      if (payload.api) {
        api = api.split('2022').join(payload.api);
      }

      var c = antiCache;

      if (payload.request.split('admin/').length > 1) c = "c=" + new Date().getTime();

      axios.get(api + payload.request + '&' + c).then(response => {
        context.commit('pdv', {
          request: payload.request,
          antiCache,
          content: response.data
        });

        if (payload && payload.onComplete) payload.onComplete();
      });
    } catch (e) {
      if (payload && payload.onError) payload.onError();
    }
  }
}

actions.votesFetch = function (context, payload) {
  if (context.state.voting.token) return;

  try {
    axios.get(state.api + 'voting/fetch/' + payload + '&c=' + (new Date().getTime())).then(response => {
      context.commit('voteInit', response.data);
    });
  } catch (e) {
    console.log(e);
  }
}

actions.vote = function (context, payload) {
  if (!context.state.voting.token) return;

  var data = {
    hash: payload.hash,
    qid: payload.qid,
    voted: new Date(),
    value: payload.value,
    ttc: Math.round((new Date().getTime() - context.state.start) / 1000),
    token: context.state.voting.token
  };

  try {
    axios.post(state.api + 'voting/add&c=' + (new Date().getTime()), data).then(() => {
      context.commit('voteAdd', data);
    });
  } catch (e) {
    console.log(e);
  }
}

export default actions;
