import {betterURL, date, colorOfParty, beautifyNumber} from '@/common/helpers';

export default {
	name: 'layout-homepage',
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
  components: {},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		tipsSenate: function () {
			return this.$store.getters.pdv('senate/all', 2022);
		},
		tipsPrimator: function () {
			var list = [];

			this.$store.state.static.commons.selected.forEach(town => {
				list.push(town.num);
			})

			return this.$store.getters.pdv('town/list/' + list.join(','));
		},
		news: function () {
			return this.$store.getters.pdv('tip/news/99', 2022);
		},
		parties: function () {
			var list = [];
			var pts = this.$store.getters.parties();

			if (pts) pts.filter(x => x.level === 5).forEach(x => list.push(x));

			list.sort((a, b) => b.nom_nominees - a.nom_nominees);

			return list;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL,
		date,
		colorOfParty,
		beautifyNumber,
		getLinkFromHash: function (item) {
			var link = "";
			var hash = Number(String(item.hash).split('-')[0]);
			var obj;

			if (hash < 100) {
				obj = this.$store.state.static.senate.list.find(x => x.id === hash);
				link = "/obvod/" + hash + '-' + betterURL(obj.display);
			} else {
				obj = this.$store.getters.town(hash);
				link = "/obec/" + hash + '-' + betterURL(obj.data[6]);
			}

			return link;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {path: "", title: "Komunální a senátní volby 2022"});
  }
};
