import axios from 'axios';
import copy from 'copy-to-clipboard';
import {date} from '@/common/helpers';

export default {
	name: 'admin-list-requests',
	props: ['list'],
	data: function () {
		return {
			reqs: [

			]
		}
	},
	computed: {
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		date,
		accept: function (item) {
			var el = this.reqs.find(x => x.item.hash === item.hash && x.item.phash === item.phash);

		// console.log(item, el);

			if (el) {
				el.result = 1;
				el.token = item.token;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/request-update', {
					token: el.token.split('.cz/admin/')[1],
					comment: null,
					request: el.item.request,
          pass: this.$store.state.auth,
					status: 1
				}).then(() => {
					el.added = true;

					// copy(el.token);
					// this.copied = true;

				});
			}
		},
		reject: function (item) {
			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/request-update', {
				comment: item.comment,
				request: item.item.request,
				pass: this.$store.state.auth,
				status: 0
			}).then(() => {
				item.added = false;
				item.result = 2;
			});
		},
		email: function (email, token) {
			return "mailto:" + email + "?subject=P%C5%99%C3%ADstup%20do%20adminu&body=Dobr%C3%BD%20den%2C%0D%0A%0D%0Azde%20najdete%20p%C5%99%C3%ADstup%20k%20va%C5%A1im%20%C3%BAdaj%C5%AFm%3A%0D%0A" + token + "%0D%0A%0D%0AKdybyste%20cokoli%20s%20adminem%20pot%C5%99ebovali%2C%20pros%C3%ADm%2C%20napi%C5%A1te.";
		},
		email2: function (token) {
			copy("Dobrý den,\n\nzde najdete přístup k vašim údajům na Programech do voleb:\n" + token + "\n\nDěkuji předem za vyplnění. Kdybyste narazili na nějaký problém, dejte prosím vědět.\n\nS pozdravem");
		}
	},
	mounted: function () {
		this.list.forEach(item => {

			var type = 4;

			if (item.phash) {
				type = 3;
			} else {
				if (item.link.split('obec').length > 1) type = 1;
				if (item.link.split('obvod').length > 1) type = 2;
			}

			this.reqs.push({
				item,
				type: type,
				comment: null,
				result: null,
				token: null,
				added: null
			})
		})
	}
};
