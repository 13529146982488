export default {
	name: 'round-button',
	props: ['type', 'data', 'color', 'addHasLabel'],
	computed: {
		showEmpty: function () {
			return ['fb', 'ig', 'tw', 'yt', 'linkedin', 'link', 'wiki', 'answers', 'people'].indexOf(this.type) === -1;
		},
		showColor: function () {
			return this.color && ['program', 'web'].indexOf(this.type) > -1 ? this.color : null
		},
		showIcon: function () {
			var icon = 'icon-none';

			if (this.data) icon = 'link';
			if (this.data && !this.showEmpty) icon = this.type;

			// if (this.type === 'web') icon = 'link';
			if (this.type === 'people') icon = 'people-symbol';

			return '/static/icon/' + icon + '.svg';
		}
	}
};
