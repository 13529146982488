import {betterURL} from '@/common/helpers';

export default {
	name: 'search-town',
	components: {},
	props: ['placeholder', 'tipsPrimator', 'cb'],
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.$store.getters.town(554499);
				if (hash === 'es') c = this.$store.getters.town(561754);

				if (c) {
					var o = {};
					o.num = c.data[0];
					o.display = c.data[6];
					o.link = '/obec/' + o.num + '-' + betterURL(o.display);
					o.okres = c.district;

					results.push(o);
				}
			}

			if (hash.length > 2) {
				var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

				Object.keys(townlist).forEach(key => {
					// console.log(key);
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						var o = {};
						o.num = item[0];
						o.display = item[6];
						o.link = '/obec/' + o.num + '-' + betterURL(o.display);
						o.okres = this.$store.getters.district(key);

						if (item[7]) {
							o.part = this.$store.getters.town(item[7]);
						}

						results.push(o);
					});
				});

			}

			results.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		},
	},
	methods: {
		select: function (item) {
			this.$emit('update', item);
			this.querySearchTown = null;
		}
	},
	mounted: function () {}
};
