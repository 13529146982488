import axios from 'axios';
import {truncate} from '@/common/helpers';

export default {
	name: 'admin-faq',
	props: ['hash', 'data'],
	data: function () {
		return {
			enabled: 0,
			all: false
		}
	},
	computed: {
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		truncate,
		setEnabled: function (value) {
			this.enabled = value;

			axios.post(this.$store.state.api + 'admin/faq-enable', {
				hash: this.hash,
				status: value,
				token: this.token
			})
		}
	},
	mounted: function () {
		if (this.data.enabled.length > 0) {
			this.enabled = this.data.enabled[0].status;
		} else {
			this.enabled = 0;
		}
	}
};
