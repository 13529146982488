import copy from 'copy-to-clipboard';
import {betterURL} from "@/common/helpers";

export default {
	name: 'admin-list-parties',
	props: ['access'],
	data: function () {
		return {
			labels: [
				{
					label: 'Hlavní strany',
					level: 5
				},
				{
					label: 'Druhá liga',
					level: 4
				},
				{
					label: 'Další strany',
					level: 3
				},
				{
					label: 'Koalice',
					level: 2
				},
				{
					label: 'Ostatní',
					level: 1
				}
			],
			copied: false
		}
	},
	methods: {
		betterURL,
		tokenLink: function (hash) {
			return 'https://2022.programydovoleb.cz/admin/' + this.access.find(x => x.hash === hash).token;
		},
		copyLink: function (path) {

				copy(path);
				this.copied = true;

			this.$store.dispatch("ge", {
				action: "copy",
				category: "share",
				label: this.$route.fullPath
			});

			setTimeout(() => this.copied = false, 2000);
		}
	}
};
