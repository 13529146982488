export default [
  {nuts: 'CZ010', id:  0, hash: 'praha', name: 'Praha'},
  {nuts: 'CZ020', id:  1, hash: 'stredocesky-kraj', name: 'Středočeský kraj'},
  {nuts: 'CZ031', id:  2, hash: 'jihocesky-kraj', name: 'Jihočeský kraj'},
  {nuts: 'CZ032', id:  3, hash: 'plzensky-kraj', name: 'Plzeňský kraj'},
  {nuts: 'CZ041', id:  4, hash: 'karlovarsky-kraj', name: 'Karlovarský kraj'},
  {nuts: 'CZ042', id:  5, hash: 'ustecky-kraj', name: 'Ústecký kraj'},
  {nuts: 'CZ051', id:  6, hash: 'liberecky-kraj', name: 'Liberecký kraj'},
  {nuts: 'CZ052', id:  7, hash: 'kralovehradecky-kraj', name: 'Královéhradecký kraj'},
  {nuts: 'CZ053', id:  8, hash: 'pardubicky-kraj', name: 'Pardubický kraj'},
  {nuts: 'CZ063', id:  9, hash: 'vysocina', name: 'Vysočina'},
  {nuts: 'CZ064', id: 10, hash: 'jihomoravsky-kraj', name: 'Jihomoravský kraj'},
  {nuts: 'CZ071', id: 11, hash: 'olomoucky-kraj', name: 'Olomoucký kraj'},
  {nuts: 'CZ072', id: 12, hash: 'zlinsky-kraj', name: 'Zlínský kraj'},
  {nuts: 'CZ080', id: 13, hash: 'moravskoslezsky-kraj', name: 'Moravskoslezský kraj'}
]
