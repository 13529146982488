import {betterURL} from '@/common/helpers';

export default {
	name: 'layout-smalls',
	props: ['hash'],
	data: function () {
		return {}
	},
  components: {},
	computed: {
		okresy: function () {
			var list = [];
			var data = this.$store.getters.getSource('obecne/okresy-flat');

			if (data) {
				data.list.forEach(x => list.push(x));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		},
		towns: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/smalls');
		},
		points: function () {
			var list = [];

			if (this.towns) {
				this.towns.single.forEach(item => {
					list.push({
						point: this.$store.getters.town(item.town.num).data,
						data: {color: '#888'}
					})
				})

				this.towns.zero.forEach(item => {
					list.push({
						point: this.$store.getters.town(item.town.num).data,
						data: {color: '#F00'}
					})
				})
			}

			return list;
		}
	},
  methods: {
		betterURL,
		sort: function (list) {
			var arr = [];

			list.forEach(x => arr.push(x));

			arr.sort((a, b) => a.town.name.localeCompare(b.town.name, 'cs'));

			return arr;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		}
	}
};
