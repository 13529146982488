import axios from 'axios';
import ResultsBlock from '@/components/results/types/results-parties/do';
import ResultsGraph from '@/components/results/main/results-element/do';
import PollsEntry from '@/components/polls-entry/do';
import PopulationDiffGraph from '@/components/population-diff-graph/do';
import {csuColor, betterURL, date, beautifyDate, beautifyNumber, gradient, getLinkType, colorOfParty, truncate} from '@/common/helpers';

export default {
	name: 'layout-town',
	props: ['id'],
	data: function () {
		return {
			suggestCurrent: {
				parties: [],
				coalition: [],
				mayor_name: null,
				mayor_party: null,
				mayor_nominee: null,
				link: null,
				author: null,
				comment: null,
				code: 1,
				election: null,
				elected: []
			},
			tick: 0
		}
	},
	components: {
		ResultsBlock, PollsEntry, PopulationDiffGraph, ResultsGraph
	},
	computed: {
		loaded: function () {
			return true;
		},
		analytics: function () {
			return this.$store.getters.pdv('system/analytics/obec*' + this.id);
		},
		people: function () {
			var p;

			var limits = [
				{pct: 0.05, limit: 0, min: 0, max: 500, label: 'do 500 obyvatel'},
				{pct: 0.04, limit: 25, min: 501, max: 3000, label: 'nad 500 do 3000 obyvatel'},
				{pct: 0.03, limit: 120, min: 3001, max: 10000, label: 'nad 3000 do 10 000 obyvatel'},
				{pct: 0.02, limit: 600, min: 10001, max: 50000, label: 'nad 10 000 do 50 000 obyvatel'},
				{pct: 0.01, limit: 1000, min: 50001, max: 150000, label: 'nad 50 000 do 150 000 obyvatel'},
				{pct: 0.005, limit: 1500, min: 150001, max: 50000000, label: 'nad 150 000 obyvatel'},
			]

			if (this.data && this.data.population && this.data.population[0] && this.data.population[0].y22) {
				p = {};
				p.population = Number(this.data.population[0].y22.split(',')[0]);
				p.snk = Math.ceil(p.population * 0.07);

				p.limit = limits.find(x => x.min < p.population && x.max > p.population);

				if (p.limit) {
					p.nk = Math.ceil(p.population * p.limit.pct);

					if (p.nk < p.limit.limit) {
						p.nk = p.limit.limit;
					}
				}
			}

			return p;
		},
		num: function () {
			return Number(this.id.split('-')[0]);
		},
		town: function () {
			if (!this.loaded) return;

			var item = this.$store.getters.town(this.num);

			if (item) {
				this.$store.dispatch("ga", {title: item.data[6] + " v komunálních volbách 2022"});
			}

			return item;
		},
		senate: function () {
			return this.data ? this.data.senate : undefined;
		},
		stats: function () {
			return this.data ? this.data.town.about.polist : undefined;
		},
		data: function () {
			var d = this.$store.getters.pdv('town/fetch/' + this.num);

			if (d && this.$route.query.hlasy) {
				setTimeout(() => this.$refs.anchors.trigger('#prepocet-na-mandaty'), 500)
			}

			return d;
		},
		dataMagistrat: function () {
			var data;

			if (this.town && this.town.data[7]) {
				data = this.$store.getters.pdv('town/fetch/' + this.town.data[7]);
			}

			return data;
		},
		latest: function () {
			if (!this.stats) return;

			var election = this.stats.volby.obce.find(x => x.year === 2018);

			this.stats.volby.obce.filter(x => x.id).sort((a, b) => a.id - b.id).forEach(el => {
				if (el.id > 20181000) election = el;
			});

			return election;
		},
		editableCurrent: function () {
			if ((!this.data || !this.stats) && this.tick) return false;

			try {
				this.suggestCurrent.coalition = [];
				this.suggestCurrent.parties = [];
				this.suggestCurrent.elected = [];

				if (this.data.town.about.current) {
					this.suggestCurrent.election = this.stats.volby.obce.find(x => x.year === this.data.town.about.current.election || x.id === this.data.town.about.current.election);
				} else {
					this.suggestCurrent.election = this.latest;
				}

				if (this.suggestCurrent.election.year === this.latest.year || this.suggestCurrent.election.id === this.latest.id) {

					var partiesInTown = [];

					this.suggestCurrent.election.parts.forEach(pts => {
						pts.results.forEach(party => {

							var o = partiesInTown.find(x => x.name === party.name);

							if (!o) {
								o = {
									id: party.id,
									reg: party.reg,
									name: party.name,
									coalition: party.coalition,
									list: [],
									selected: false
								};

								partiesInTown.push(o);
							}

							if (party.list && party.list.length > 0) {

								party.list.forEach(x => o.list.push(x));

								if (this.data.town.about.current && this.data.town.about.current.coalition && this.data.town.about.current.coalition.find(x => x === o.id)) o.selected = true;

								if (!this.suggestCurrent.coalition.find(x => x.name === o.name)) this.suggestCurrent.coalition.push(o);

								party.list.forEach(person => {
									var po = {
										name: person.name,
										id: o.id,
										party: this.partyByID(o.id),
										color: this.colorOfParty((this.partyByID(o.id) || {reg: 80}).reg, o.name)
									};

									this.suggestCurrent.elected.push(po);
								})
							}
						});
					});

					partiesInTown.forEach(party => {
						if (party.list.length > 0) {
							if (party.reg && party.reg != 80 && party.reg != 90) {
								var p = this.$store.getters.party(party.reg);

								if (p && !p.coalition && !this.suggestCurrent.parties.find(x => x.party.reg === p.reg)) this.suggestCurrent.parties.push({party: p, primary: !!party.list});

								if (p && p.coalition) {
									p.coalition.forEach(member => {
										var p1 = this.$store.getters.party(member);

										if (p1 && !this.suggestCurrent.parties.find(x => x.party.reg === p1.reg)) this.suggestCurrent.parties.push({party: p1, primary: !!party.list});
									})
								}
							}
						}
					});

					this.suggestCurrent.mayor_name = (this.data.town.about.current && this.data.town.about.current.mayor_name) ? this.data.town.about.current.mayor_name : '';
					this.suggestCurrent.mayor_nominee = (this.data.town.about.current && this.data.town.about.current.mayor_party) ? this.data.town.about.current.mayor_party : -2;
					this.suggestCurrent.mayor_party = (this.data.town.about.current && this.data.town.about.current.mayor_member) ? this.data.town.about.current.mayor_member : -2;
					this.suggestCurrent.mayor_nominant = (this.data.town.about.current && this.data.town.about.current.mayor_nominee) ? this.data.town.about.current.mayor_nominee : -2;

					this.suggestCurrent.parties.sort((a, b) => a.party.name.localeCompare(b.party.name, 'cs'));

					this.suggestCurrent.elected.sort((a, b) => a.name[2].localeCompare(b.name[2], 'cs'));
				}

				// console.log(1);

				return true;
			} catch (e) {
				return false;
			}
		},
		news: function () {
			return this.data ? this.data.news : [];
		},
		dominance: function () {
			var value = 0;
			var max = 0;
			var pct = 0;

			if (this.data) {
				this.data.town.about.current.coalition.forEach(a => {
					var b = this.partyByID(a);

					if (b && b.list) {
						value += b.list.length;
					}
				});

				max = this.stats.stats.seats;

				pct = Math.floor(10000 * value / max)/100;
			}

			return {value, max, pct};
		},
		population: function () {
			var list = [];
			var diff = {
				population: {
					total: 0,
					avgage: 0
				},
				male: {
					total: 0,
					avgage: 0
				},
				female: {
					total: 0,
					avgage: 0
				},
				maxY2Ydiff: 0
			};

			if (this.data && this.data.town.about.population && this.data.town.about.population.num) {
				Object.keys(this.data.town.about.population).forEach(key => {
					if (key === 'num' || this.data.town.about.population[key] === null) {
						// do nothing
					} else {

						var json = JSON.parse("[" + this.data.town.about.population[key] + "]");

						var obj = {
							year: Number(20 + key.split('y')[1]),
							population: {
								total: json[0],
								avgage: json[3],
								diff: list.length === 0 ? 0 : json[0] - list[list.length - 1].population.total
							},
							male: {
								total: json[1],
								avgage: json[4]
							},
							female: {
								total: json[2],
								avgage: json[5]
							}
						}

						if (Math.abs(obj.population.diff) > diff.maxY2Ydiff) diff.maxY2Ydiff = Math.abs(obj.population.diff);

						list.push(obj);
					}
				});

				diff.population.total = Math.round(list[list.length - 1].population.total - list[0].population.total);
				diff.population.avgage = Math.round(100 * (list[list.length - 1].population.avgage - list[0].population.avgage)) / 100;
				diff.male.total = Math.round(list[list.length - 1].male.total - list[0].male.total);
				diff.male.avgage = Math.round(100 * (list[list.length - 1].male.avgage - list[0].male.avgage)) / 100;
				diff.female.total = Math.round(list[list.length - 1].female.total - list[0].female.total);
				diff.female.avgage = Math.round(100 * (list[list.length - 1].female.avgage - list[0].female.avgage)) / 100;
			}

			return {list, diff};
		},
		partsOfTown: function () {
			if (this.town) {
				var list = this.$store.getters.getSource('volby/kom/2022/static/obce-flat').list[this.town.district.nuts].filter(x => x[7] && x[7] === this.town.data[0])
				return list.sort((a, b) => a[6].localeCompare(b[6], 'cs'));
			} else {
				return [];
			}
		},
		multipleObvod: function () {
			var list = []

			if (this.stats && !this.stats.hierarchy.obvod.id && this.partsOfTown.length > 0) {
				this.partsOfTown.forEach(part => {
					var obv = list.find(x => x.obvod === part[1]);

					if (!obv) {
						obv = {
							obvod: part[1],
							about: this.$store.state.static.senate.list.find(x => x.id === part[1]),
							list: []
						}

						list.push(obv);
					}

					obv.list.push(part);
				});
			}

			return list;
		},
		obvod: function () {
			return this.data ? this.data.town.about.hierarchy.senat : null;
		},
		elections: function () {
			var data;
			var latest;

			if (this.data && this.data.senate.about) {
				data = this.data.senate.about.polist;
			}

			if (data) {
				data.elections.sort((a, b) => b.date - a.date);
				latest = data.elections[0];
			}

			return latest;
		},
		latestElection: function () {
			var list = [];

			if (this.stats) {
				this.stats.volby.obce.forEach(el => list.push(el));
				list.sort((a, b) => (b.id || b.year * 10000) - (a.id || a.year * 10000));
			}

			return list[0];
		},
		obvody: function () {
			var list = {
				count: 1,
				list: [[],[],[],[],[],[],[],[],[],[]]
			};

			if (this.data && this.data.town.list.find(x => x.csu.main.obvod)) {
				this.data.town.list.forEach(x => {
					if (x.csu.main.obvod > list.count) list.count = x.csu.main.obvod;
					list.list[x.csu.main.obvod - 1].push(x);
				})
			}

			return list;
		},
		topics: function () {
			var arr = ['Doprava', 'Parkování', 'Energie', 'Bydlení', 'Zdražování', 'Zeleň', 'Bezpečnost', 'Rozvoj', 'Vláda'];

			if (this.data && this.data.topics) {
				this.data.topics.forEach(top => arr.push(top.content));
			}

			arr.push("Něco jiné");

			return arr;
		}
	},
	methods: {
		$: function (hash) {
			return this.parties.list.find(x => x.hash === hash)
		},
		betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, csuColor, truncate,
		jp: JSON.parse,
		suggestCurrentPersonSelect: function (item) {
			this.suggestCurrent.mayor_name = item.name;
			this.suggestCurrent.mayor_nominee = item.nominee;
			this.suggestCurrent.mayor_party = -2;

			this.$refs.suggestCurrentList.hide();
		},
		partyByID: function (id, key = 2018) {
			if (!this.stats) return;

			if (this.num === 500216 || this.num === 544779) {
				var party = this.stats.volby.obce.find(x => x.year === key || x.id === key).parts[0].results.find(x => x.id === id);

				var px = {
					name: party.name,
					id: party.id,
					reg: party.reg,
					votes: party.votes,
					pct: party.pct,
					list: []
				}

				party.list.forEach(x => px.list.push(x));

				this.stats.volby.obce.find(x => x.year === key || x.id === key).parts.forEach((pt, i) => {
					if (i > 0) {
						var item = pt.results.find(x => x.name === px.name); // .list.forEach(p => px.list.push(p));
						// console.log(item);

						if (item.list) item.list.forEach(p => px.list.push(p));
					}
				})

				// console.log(px);

				return px;
			} else if (this.latest) {
				return this.latest.parts[0].results.find(x => x.id === id);
			}
		},
		suggestCurrent_send: function () {
			if (this.suggestCurrent.code === 1 && this.suggestCurrent.link && this.suggestCurrent.link.length > 8) {
				this.suggestCurrent.code = 2;

				var coalition = [];

				this.suggestCurrent.coalition.forEach(member => {
					if (member.selected === true) coalition.push(member.id)
				})

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/current', {
					coalition: coalition.length > 0 ? coalition.join(',') : 'null',
					name: this.suggestCurrent.mayor_name,
					party: this.suggestCurrent.mayor_nominee,
					member: this.suggestCurrent.mayor_party === -2 ? 'null' : this.suggestCurrent.mayor_party,
					nominee: this.suggestCurrent.mayor_nominant === -2 ? 'null' : this.suggestCurrent.mayor_nominant,
					link: encodeURIComponent(this.suggestCurrent.link),
					author: encodeURIComponent(this.suggestCurrent.author),
					comment: encodeURIComponent(this.suggestCurrent.comment),
					num: this.num,
					election: this.latest.year || this.latest.id,
					page: this.$route.fullPath
				}).then(() => {
					this.suggestCurrent.code = 3;

					setTimeout(() => {
						this.suggestCurrent.code = 1;
						this.$refs.suggestCurrentForm.hide();
					}, 5000);
				});
			}
		},
		makeICOString: function (ico) {
			var f = ico.toString();
			while (f.length < 8) f = '0' + f;
			return f;
		},
		support: function (data) {
			var list = [];
			var json = JSON.parse("[" + data + "]");

			json.forEach(member => {
				if (typeof member === 'number') {
					list.push(this.$store.getters.party(member))
				} else {
					list.push({
						name: member,
						short: member,
						color: colorOfParty(null, member)
					})
				}
			});

			return list;
		},
		sort: function (list) {
			var arr = [];

			list.forEach(x => arr.push(x));

			arr.sort((a, b) => a[6].localeCompare(b[6], 'cs'));

			return arr;
		},
		active: function (reg) {
			var obj = {
				icon: 'against',
				link: null
			}

			if (this.data && this.data.town.list) {
				this.data.town.list.forEach(member => {
					if (member.csu.main.party_coalition.find(x => x === reg)) {
						obj.link = this.$route.path + '/' + member.csu.main.hash;
						obj.icon = 'check';
					}
				});
			}

			return obj;
		},
		activeSenate: function (reg) {
			var obj = {
				icon: 'none',
				link: null,
				label: 'nikoho'
			}

			if (this.data && this.obvod && this.data.senate.list) {
				this.data.senate.list.forEach(member => {
					if (member.csu.main.coalition.find(x => x === reg) || member.csu.main.member === reg || member.csu.main.nominee === reg) {
						obj.link = '/obvod/' + this.obvod + '-' + betterURL(this.senate.about.polist.name) + '/' + member.csu.main.hash;
						obj.icon = 'check';
						obj.label = member.csu.main.cand_name[0] + '. ' + member.csu.main.cand_family;
					}
				});
			}

			return obj;
		},
		hl: function () {
			return encodeURIComponent(this.town.data[6]);
		}
	},
	mounted: function () {
		window.scrollTo(0, 1);
	},
	watch: {
		id: function () {
			this.tick++;
			window.scrollTo(0, 1);
		}
	}
};
