export default {
	name: 'admin-list-heroes',
	props: ['towns', 'senate', 'links', 'contacts', 'logo', 'color'],
	computed: {
		townsUsed: function () {
			var arr = [];

			this.towns.forEach(item => {

				var obj = arr.find(x => x.csu.num === item.party.num);

				if (!obj) {
					obj = {
						csu: {
							num: item.party.num,
							about: this.$store.getters.town(item.party.num)
						},
						list: []
					}

					arr.push(obj);
				}

				obj.list.push(item);
			})
			arr.forEach(item => {
				item.list.sort((a, b) => a.party.party_name.localeCompare(b.party.party_name, 'cs'));
			})

			arr.sort((a, b) => a.csu.about.data[6].localeCompare(b.csu.about.data[6], 'cs'));

			return arr;
		},
		senateUsed: function () {
			var arr = [];

			this.senate.forEach(item => {

				var obj = arr.find(x => x.csu.id === item.obvod);

				if (!obj) {
					obj = {
						csu: {
							id: item.obvod,
							about: this.$store.getters.obvod(item.obvod)
						},
						list: []
					}

					arr.push(obj);
				}

				obj.list.push(item);
			})

			arr.forEach(item => {
				item.list.sort((a, b) => a.cand_family.localeCompare(b.cand_family, 'cs'));
			})

			arr.sort((a, b) => a.csu.id - b.csu.id);

			return arr;
		}
	}
};
