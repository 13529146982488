import {betterURL} from '@/common/helpers';

export default {
	name: 'layout-district',
	props: ['hash'],
	data: function () {
		return {}
	},
  components: {},
	computed: {
		okresy: function () {
			return this.$store.getters.getSource('obecne/okresy-flat');
		},
		towns: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/obce-flat');
		},
		obvody: function () {
			return this.$store.getters.getSource('obecne/senatni-volby');
		},
		about: function () {
			var okres = "Načítám";
			var kraj = "...";
			var nuts1;
			var nuts2;

			if (this.okresy && this.towns) {
				var ok = this.okresy.list.find(x => betterURL(x.name) === this.hash)

				if (ok) {
					okres = ok.name;
					nuts2 = ok.nuts;

					var di = this.$store.state.static.regions.find(x => nuts2.split(x.nuts).length === 2);

					if (di) {
						kraj = di.name;
						nuts1 = di.nuts;
					}
				}
			}

			this.$store.dispatch("ga", {title: "Okres " + okres});

			return {nuts1, kraj, nuts2, okres}
		},
		list: function () {
			var list = [];

			if (this.towns && this.about.nuts2) {
				this.towns.list[this.about.nuts2].forEach(item => list.push(item));
			}

			list = this.sort(list);

			return list;
		},
		listMap: function () {
			var list = [];

			if (this.towns && this.about.nuts2) {
				this.towns.list[this.about.nuts2].forEach(item => {
					if (!item[7]) list.push({point: item, data: {color: item[1] % 3 === 1 ? '#009206' : '#d72027'}})
				});
			}

			return list;
		},
		listObvody: function () {
			var list = [];

			if (this.towns && this.obvody && this.about.nuts2) {
				this.towns.list[this.about.nuts2].forEach(item => {
					var obv = list.find(x => x.id === item[1]);

					if (!obv && item[1] > 0) {
						obv = {
							id: item[1],
							name: (this.obvody.list.find(x => x.id === item[1]) || {name: '?'}).name,
							active: item[1] % 3 === 1
						}

						list.push(obv);
					}
				});
			}

			list.sort((a, b) => a.id - b.id);

			return list;
		}
	},
  methods: {
		betterURL,
		sort: function (list) {
			var arr = [];

			list.forEach(x => arr.push(x));

			arr.sort((a, b) => a[6].localeCompare(b[6], 'cs'));

			return arr;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		}
	}
};
