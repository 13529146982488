export default {
	name: 'layout-homepage',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		tipsPrimator: function () {
			return this.$store.getters.pdv('system/fix-photo', 2022);
		}
	},
  methods: {
  },
  mounted: function () {}
};
