import axios from 'axios';
import {truncate} from '@/common/helpers';

export default {
	name: 'hash-reform',
	props: ['data'],
	components: {},
	data: function () {
		return {
			hash: null,
			sent: false,
			sending: false,
			// showHints: false
		}
	},
	computed: {
		valid: function () {
			return true;
		}
	},
	methods: {
		truncate,
		send: function (id, hash, phash) {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=system/hash4', {
					id,
					hash,
					phash
				}).then(() => {
					this.sending = false;
					this.sent = true;

					this.hash = hash;

					// setTimeout(() => this.another(), 1500);
				});
			}
		}
	}
};
