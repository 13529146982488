import {betterURL, date, colorOfParty, csuColor, beautifyNumber} from '@/common/helpers';

export default {
	name: 'layout-homepage-towns',
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
  components: {},
	computed: {
		loaded: function () {
			return true;
		},
		townsNominee: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/town_hierarchy');
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.$store.getters.town(554499);
				if (hash === 'es') c = this.$store.getters.town(561754);

				if (c) {
					results.push(this.$store.getters.town(c));
				}
			}

			if (hash.length > 2) {
				var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

				Object.keys(townlist).forEach(key => {
					// console.log(key);
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						results.push({
							data: item,
							district: this.$store.getters.district(key),
							part: item[7] ? this.$store.getters.town(item[7]) : null,
							hash: item[0] + '-' + betterURL(item[6])
						});
					});
				});

			}

			results.sort((a, b) => a.data[6].localeCompare(b.data[6], 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		},
		data: function () {
			var list = [];

			this.$store.state.static.commons.selected.forEach(town => {
				list.push(town.num);
			})

			return this.$store.getters.pdv('town/list/' + list.join(','));
		},
		sorted: function () {
			if (!this.data || this.loaded === false) return;

			var list = [];

			this.$store.state.static.commons.selected.forEach(town => {
				var obj = {
					town,
					list: []
				}

				this.data.list.find(x => x.about.hierarchy.num === town.num).list.forEach(item => {
					var o = {
						hash: item.csu.main.hash,
						display: '', // item.csu && item.csu.candidates && this.csu.candidates[0] ? item.csu.candidates[0][2] + '. ' + item.csu.candidates[0][3] : undefined,
						color: csuColor(item), // this.colorOfParty(item.party),
						data: item
					}

					// if (!item.csu.candidates[0]) console.log(item);

					obj.list.push(o);
				});

				obj.list.sort((a, b) => a.data.csu.main.party_short.localeCompare(b.data.csu.main.party_short, 'cs'));

				list.push(obj);
			});

			return list;
		},
		news: function () {
			return this.data ? this.data.news : undefined;
		},
		towns: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/obce-flat') || {list: {}};
		},
		mainTowns: function () {
			var list = [];

			if (this.towns && this.townsNominee) {
				Object.keys(this.towns.list).forEach(key => {
					// var obv = this.towns.list[key].filter(x => x[1] === this.num);
					// if (obv.length > 0) {
						this.towns.list[key].forEach(item => {
							if (item[5] > 20 && item[4] != 5) {
								var obj = {
									point: item,
									data: {}
								}

								var selected = this.townsNominee.find(x => x.num === item[0]);

								if (selected) {
									obj.data.nominee = selected.mayor_nominee || 80;
								}

								if (selected && this.$store.getters.party(selected.mayor_nominee)) {
									obj.data.color = this.$store.getters.party(selected.mayor_nominee).color;
								}

								list.push(obj);
							}
						});
					// }
				});
			}

			return list;
		},
		stats: function () {
			var results = {
				towns: [],
				members: []
			}

			this.$store.getters.parties().forEach(party => {
				if (party.reg != 80 && party.reg != 99 && !party.coalition) {
					results.towns.push(party);
					results.members.push(party);
				}
			});

			results.towns.sort((a, b) => (b.nom_towns || 0) - (a.nom_towns || 0));
			results.members.sort((a, b) => (b.nom_members || 0) - (a.nom_members || 0));

			return results;
		},
		statsOld: function () {
			var results = {
				all: [],
				main: [],
				all80: 4814,
				main80: 0
			}

			if (this.mainTowns.length > 0) {
				this.mainTowns.forEach(town => {
					var obj = results.main.find(x => x.reg === town.data.nominee);

					if (town.data.nominee === 80 || town.data.nominee === 99) {
						results.main80++;
					} else {
						if (!obj) {
							obj = {
								reg: town.data.nominee,
								value: 0,
								party: this.$store.getters.party(town.data.nominee),
								color: this.$store.getters.party(town.data.nominee) ? this.$store.getters.party(town.data.nominee).color : '#ddd'
							}

							results.main.push(obj);
						}

						obj.value++;
					}
				});
			}

			var parties = [[1, 270], [5, 4], [7, 159], [47, 59], [53, 188], [166, 393], [703, 21], [720, 6], [721, 38], [768, 74], [88, 15], [181, 20], [129,19]];

			parties.forEach(party => {
				var obj = {
					reg: party[0],
					value: party[1],
					party: this.$store.getters.party(party[0]),
					color: this.$store.getters.party(party[0]).color
				}

				results.all.push(obj);
			})

			results.all.sort((a, b) => b.value - a.value);
			results.main.sort((a, b) => b.value - a.value);

			return results;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL,
		date,
		colorOfParty,
		beautifyNumber,
		getLinkFromHash: function (item) {
			var link = "";
			var hash = Number(String(item.hash).split('-')[0]);
			var obj;

			if (hash < 100) {
				obj = this.$store.state.static.senate.list.find(x => x.id === hash);
				link = "/obvod/" + hash + '-' + betterURL(obj.display);
			} else {
				obj = this.$store.getters.town(hash);
				link = "/obec/" + hash + '-' + betterURL(obj.data[6]);
			}

			return link;
		},
		sortByLastName: function (list) {
			var l = [];
			list.forEach(x => l.push(x));
			l.sort((a, b) => (a.display || '').split(' ')[(a.display || '').split(' ').length - 1].localeCompare((b.display || '').split(' ')[(b.display || '').split(' ').length - 1], 'cs'));
			return l;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {path: "", title: "Komunální volby 2022"});
  }
};
