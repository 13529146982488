import {betterURL} from '@/common/helpers';

export default {
	name: 'search-party',
	components: {},
	props: ['placeholder', 'tipsPrimator'],
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.getters.parties(); // state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) {
				// console.log("not loaded");
				return null;
			}
			if (this.querySearchTown.length < 2) {
				// console.log("short");
				return null;
			}

			this.querySearchTownSearching = true;

			var results = [];
			var xsearch = betterURL(this.querySearchTown);

			// console.log(xsearch);

			this.$store.state.dynamic.pdv.find(x => x.request === 'party/list').content.list.filter(x => betterURL(x.name).indexOf(xsearch) > -1).forEach(x => {
				if (x.name.indexOf('Koalice') === -1 && x.name.indexOf('Sdružení') === -1) {
					results.push(x);
				}
			});

			results.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		},
	},
	methods: {
		confirm: function (reg) {
			this.$emit('update', reg);
			this.querySearchTown = null;
		}
	},
	mounted: function () {}
};
