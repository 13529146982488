import store from '@/store/store';
import {betterURL} from '@/common/helpers';

const getters = {
  vuexState: state => state,
  demo: (state) => (id) => {
    return state.structure.find(item => item.id === id)
  }
};

getters.getSource = (state) => (source) => {
  var lookup = state.dynamic.source.find(s => s.source === source);

  if (lookup) {
    return lookup.content;
  } else {
    new Promise((resolve, reject) => {
      store.dispatch('fetchSource', {
        source: source,
        onComplete: () => resolve(),
        onError: () => reject(new Error('load fail'))
      });
    }).then((resolver, rejected) => {
      if (rejected) {
        return undefined;
      } else {
        lookup = state.dynamic.source.find(s => s.source === source);
        return lookup.content;
      }
    });
  }
}

getters.getPromise = (state) => (source) => {
  return new Promise((resolve) => {
    var lookup = state.dynamic.source.find(s => s.source === source);

    if (lookup) {
      resolve(lookup.content);
    } else {
      new Promise((resolve, reject) => {
        store.dispatch('fetchSource', {
          source: source,
          onComplete: () => resolve(),
          onError: () => reject(new Error('load fail'))
        });
      }).then(() => {
        lookup = state.dynamic.source.find(s => s.source === source);
        resolve(lookup.content);
      });
    }
  })
}

getters.parties = (state) => () => {
  return state.dynamic.pdv.find(x => x.request === 'party/list').content.list;
}

getters.party = (state) => (id) => {
  // var lookup = {
  //   color: '#eee',
  //   name: 'neznámý údaj',
  //   short: '?',
  //   logo: '/static/missing.png'
  // };

  var parties = state.dynamic.pdv.find(x => x.request === 'party/list');
  var lup = null;

  if (parties) {
    if (typeof id === 'string') {
      lup = parties.content.list.find(x => x.hash === id);
    }
    if (typeof id === 'number') {
      lup = parties.content.list.find(x => x.reg === id);
    }
  }

  return lup;
}

getters.district = (state) => (id) => {
  var lookup = {};

  var data = state.dynamic.source.find(x => x.source === 'obecne/okresy-flat');

  if (data) {
    lookup = data.content.list.find(x => x.nuts === id)
  }

  return lookup;
}

getters.obvod = (state) => (id) => {
  var obvod = state.static.senate.list.find(x => x.id === id);

  if (obvod) {
    return {
      data: obvod,
      hash: obvod.id + '-' + betterURL(obvod.display)
    }
  } else {
    return;
  }
}

getters.town = (state) => (id) => {
  var lookup = {};

  var towns = state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat');

  if (towns) {

    id = Number(String(id).split('-')[0]);

    Object.keys(towns.content.list).forEach(key => {
      var data = towns.content.list[key].find(x => x[0] === id);

      if (data) {
        lookup = {
          hash: data[0] + '-' + betterURL(data[6]),
          district: store.getters.district(key),
          data
        };

        if (data[7]) {
          lookup.part = store.getters.town(data[7])
        }
      }
    });
  }

  return lookup;
}

getters.pdv = (state) => (request, api) => {
  var lookup = state.dynamic.pdv.find(s => s.request === request);

  // if (request.split('admin/').length > 1) {
  //   lookup = null;
  // }

  if (lookup) {
    return lookup.content;
  } else {
    new Promise((resolve, reject) => {
      store.dispatch('pdv', {
        request: request,
        api: api,
        onComplete: () => resolve(),
        onError: () => reject(new Error('load fail'))
      });
    }).then((resolver, rejected) => {
      if (rejected) {
        return undefined;
      } else {
        lookup = state.dynamic.pdv.find(s => s.request === request);
        return lookup.content;
      }
    });
  }
}

export default getters;
