export default {
	name: 'grid-or-list',
	props: ['list', 'type', 'always', 'rnd2'],
	data: function () {
		return {
			width: 0
		}
	},
	computed: {
		view: function () {
			return this.$store.state.personal.preferredView;
		},
		showGrid: function () {
			if (this.always && this.always != 'list' && this.always != 'result') {
				return true;
			} else {
				if (this.view === 'list' || this.view === 'result') {
					return false;
				} else {
					return true;
				}
			}
		},
		sortedList: function () {
			if (this.view === 'result') {
				var list = [];

				this.list.forEach((item) => {
					list.push(item);
				});


				if (this.type === 'senate') {
					list.sort((a, b) => b.csu.main.result1_pct - a.csu.main.result1_pct);
				}
								if (this.type === 'rnd2') {
									list.sort((a, b) => b.csu.main.result2_pct - a.csu.main.result2_pct);
								}

				if (this.type === 'leader') {
					list.sort((a, b) => b.csu.main.results_pct - a.csu.main.results_pct);
				}

				return list;
			} else {
				return this.list
			}
		}
	},
	mounted: function () {
		this.width = window.innerWidth;
		window.addEventListener('resize', () => this.width = window.innerWidth);
	}
};
