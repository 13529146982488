import Vue from 'vue';
import Router from 'vue-router';
import LayoutHomepage from '@/layout/homepage/do';
import LayoutHomepageSenate from '@/layout/homepage-senate/do';
import LayoutHomepageTowns from '@/layout/homepage-towns/do';
import LayoutRegions from '@/layout/regions/do';
import LayoutDistrict from '@/layout/district/do';
import LayoutSmalls from '@/layout/smalls/do';
import LayoutAbout from '@/layout/about/do';
import LayoutTown from '@/layout/town/do';
import LayoutSenate from '@/layout/senate/do';
import LayoutCompareAnswersTown from '@/layout/compare/answers-town/do';
import LayoutCompareAnswersSenate from '@/layout/compare/answers-senate/do';
import LayoutParty from '@/layout/party/do';
import LayoutParties from '@/layout/parties/do';
import LayoutNews from '@/layout/news/do';
import LayoutVoting from '@/layout/voting/do';
import LayoutSuggestSenate from '@/layout/suggest/senate/do';
import LayoutSuggestTown from '@/layout/suggest/town/do';
import LayoutElectionParty from '@/layout/election-party/do';
import LayoutElectionPerson from '@/layout/election-person/do';
import LayoutElectionCandidate from '@/layout/election-candidate/do';
import LayoutPrognosticForm from '@/layout/prognostic/form/do';
import LayoutPrognosticTicket from '@/layout/prognostic/ticket/do';
import LayoutWidgets from '@/layout/widgets/do';
import LayoutAdmin from '@/admin/main/do';
import LayoutAdminRequest from '@/layout/request/detail/do';
import HelperHash from '@/layout/helper/hash/do';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LayoutHomepage',
      component: LayoutHomepage
    },
    {
      path: '/widgety',
      name: 'LayoutWidgets',
      component: LayoutWidgets
    },
    {
      path: '/senatni-volby',
      name: 'LayoutHomepageSenate',
      component: LayoutHomepageSenate
    },
    {
      path: '/komunalni-volby',
      name: 'LayoutHomepageTowns',
      component: LayoutHomepageTowns
    },
    {
      path: '/admin',
      name: 'LayoutAdminStatic',
      component: LayoutAdmin
    },
    {
      path: '/admin/:token',
      name: 'LayoutAdmin',
      component: LayoutAdmin,
      props: true
    },
    {
      path: '/admin-zadost/:id',
      name: 'LayoutAdminRequest',
      component: LayoutAdminRequest,
      props: true
    },
    {
      path: '/kraje-a-okresy',
      name: 'LayoutRegions',
      component: LayoutRegions
    },
    {
      path: '/jak-volit',
      name: 'LayoutVoting',
      component: LayoutVoting
    },
    {
      path: '/kraje-a-okresy/vybrana-zastupitelstva',
      name: 'LayoutSmalls',
      component: LayoutSmalls
    },
    {
      path: '/kraje-a-okresy/:hash',
      name: 'LayoutDistrict',
      component: LayoutDistrict,
      props: true
    },
    {
      path: '/o-projektu',
      name: 'LayoutAbout',
      component: LayoutAbout
    },
    {
      path: '/archiv-novinek',
      name: 'LayoutNews',
      component: LayoutNews
    },
    {
      path: '/prognosticka-hra',
      name: 'LayoutPrognosticForm',
      component: LayoutPrognosticForm
    },
    {
      path: '/prognosticka-hra/ticket/:id',
      name: 'LayoutPrognosticTicket',
      component: LayoutPrognosticTicket,
      props: true
    },
    {
      path: '/obec/:id',
      name: 'LayoutTown',
      component: LayoutTown,
      props: true
    },
    {
      path: '/obec/:id/porovnani-odpovedi',
      name: 'LayoutCompareAnswersTown',
      component: LayoutCompareAnswersTown,
      props: true
    },
    {
      path: '/obec/:townHash/:hash',
      name: 'LayoutElectionParty',
      component: LayoutElectionParty,
      props: true
    },
    {
      path: '/obec/:townHash/:hash/:phash',
      name: 'LayoutElectionPerson',
      component: LayoutElectionPerson,
      props: true
    },
    {
      path: '/obvod/:id',
      name: 'LayoutSenate',
      component: LayoutSenate,
      props: true
    },
    {
      path: '/obvod/:id/porovnani-odpovedi',
      name: 'LayoutCompareAnswersSenate',
      component: LayoutCompareAnswersSenate,
      props: true
    },
    {
      path: '/obvod/:obvodHash/:hash',
      name: 'LayoutElectionCandidate',
      component: LayoutElectionCandidate,
      props: true
    },
    {
      path: '/strana/:id',
      name: 'LayoutParty',
      component: LayoutParty,
      props: true
    },
    {
      path: '/strany',
      name: 'LayoutParties',
      component: LayoutParties,
      props: true
    },
    {
      path: '/navrh/kandidat-do-senatu',
      name: 'LayoutSuggestSenate',
      component: LayoutSuggestSenate
    },
    {
      path: '/navrh/strana-koalice-ci-uskupeni',
      name: 'LayoutSuggestTown',
      component: LayoutSuggestTown
    },
    {
      path: '/helper_hash',
      name: 'HelperHash',
      component: HelperHash
    }
  ]
});

export default router;
