export default [
  {id:  1, display: "Karlovy Vary", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=1"},
  {id:  4, display: "Most", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=4"},
  {id:  7, display: "Plzeň-město", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=7"},
  {id: 10, display: "Český Krumlov", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=10"},
  {id: 13, display: "Tábor", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=13"},
  {id: 16, display: "Beroun", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=16"},
  {id: 19, display: "Praha 11", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=19"},
  {id: 22, display: "Praha 10", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=22"},
  {id: 25, display: "Praha 6", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=25"},
  {id: 28, display: "Mělník", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=28"},
  {id: 31, display: "Ústí nad Labem", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=31"},
  {id: 34, display: "Liberec", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=34"},
  {id: 37, display: "Jičín", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=37"},
  {id: 40, display: "Kutná Hora", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=40"},
  {id: 43, display: "Pardubice", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=43"},
  {id: 46, display: "Ústí nad Orlicí", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=46"},
  {id: 49, display: "Blansko", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=49"},
  {id: 52, display: "Jihlava", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=52"},
  {id: 55, display: "Brno-město", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=55"},
  {id: 58, display: "Brno-město", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=58"},
  {id: 61, display: "Olomouc", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=61"},
  {id: 64, display: "Bruntál", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=64"},
  {id: 67, display: "Nový Jičín", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=67"},
  {id: 70, display: "Ostrava-město", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=70"},
  {id: 73, display: "Frýdek-Místek", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=73"},
  {id: 76, display: "Kroměříž", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=76"},
  {id: 79, display: "Hodonín", link: "https://www.senat.cz/senat/volby/hledani/o_obvodu.php?ke_dni=26.10.2021&O=13&kod=79"}
]
