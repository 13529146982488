export default {
	name: 'vote-element-multiple',
	props: ['hash', 'qid', 'question', 'answers', 'type', 'limit', 'headline', 'values', 'colors'],
	components: {},
	data: function () {
		return {
			voted: null,
			today: false,
			selected: []
		}
	},
	computed: {
		town: function () {
			return this.hash.split('-')[0].length > 5
		},
		status: function () {
			var stat = 1;

			if (this.voted != null && this.today === true) {
				stat = 2;
			} else if (this.$store.state.voting.votes.find(x => x.hash === this.hash && x.qid === this.qid)) {
				stat = 4;
			}
			//  else if (this.town && this.$store.state.voting.home.town != this.hash && this.$store.state.voting.home.town != null) {
			// 	stat = 3;
			// } else if (!this.town && this.$store.state.voting.home.obvod != this.hash && this.$store.state.voting.home.obvod != null) {
			// 	stat = 3;
			// }

			return stat;
		},
		cast: function () {
			return this.$store.state.voting.votes.find(x => x.hash === this.hash && x.qid === this.qid);
		},
		casted: function () {
			return this.cast ? Math.round(new Date(this.cast.voted).getTime()/1000) : null;
		},
		castedValue: function () {
			return this.cast ? JSON.parse('[' + this.cast.value + ']') : null;
		},
		data: function () {
			return this.$store.getters.pdv('voting-results/fetch/' + this.hash + '___' + this.qid);
		},
		results: function () {
			var arr = [];

			if (this.data) {
				Object.keys(this.data.values).forEach(key => {
					arr.push({
						id: Number(key.split('_')[1]),
						value: this.data.values[key]
					});
				});
			}

			return arr;
		}
	},
	methods: {
		toggle: function (index) {
			var e = this.selected.find(x => x === index);

			if (e) {
				this.selected.splice(this.selected.indexOf(index), 1);
			} else if (this.selected.length < this.limit) {
				this.selected.push(index);
			}
		},
		vote: function () {
			if (this.voted) return;

			var vals = [];

			this.selected.forEach(s => {
				vals.push(this.values ? this.values[s] : s);
			});

			this.voted = vals.join(',');
			this.today = true;

			this.$store.dispatch("vote", {
				hash: this.hash,
				qid: this.qid,
				value: vals.join(',')
			})
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		},
		v: function (index) {
			return this.values ? this.values[index] : index
		},
		icv: function (value) {
			return !!(this.castedValue && this.castedValue.indexOf(value) > -1)
		}
	},
	mounted: function () {
		var vote = this.cast;

		if (vote) this.voted = vote.value;
	}
};
