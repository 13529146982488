import axios from 'axios';

export default {
	name: 'faq-question',
	props: ['hash', 'data', 'all'],
	data: function () {
		return {
			status: null,
			answer: null,
			originalAnswer: null,
			sent: false,
			sending: false
		}
	},
	computed: {
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		update: function (status) {
			if (this.sending) return;
			this.sending = true;
			this.status = status;

			axios.post(this.$store.state.api + 'admin/faq-update', {
				answer: this.answer,
				status: this.status,
				id: this.data.id,
				hash: this.hash,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					this.sending = false;
					this.originalAnswer = this.answer;
					this.sent = true;

					setTimeout(() => this.sent = false, 2000);
				}
			})
		}
	},
	mounted: function () {
		this.status = this.data.status;
		this.answer = this.data.answer;
		this.originalAnswer = this.data.answer;
	}
};
