import {colorByData} from '@/common/helpers';

export default {
	name: 'admin-party',
	props: ['town', 'csu', 'vertical', 'support', 'color'],
	computed: {
		background: function () {
			return colorByData(this.csu.main.party_coalition, this.support[0], this.color[0]);
		}
	}
};
