import {date} from '@/common/helpers';

export default {
	name: 'request-detail',
	props: ['id'],
	data: function () {
		return {
			copied: false
		}
	},
  components: {},
	computed: {
		data: function () {
			return this.$store.getters.pdv('admin/request-fetch/' + this.id);
		},
		path: function () {
			return 'https://2022.programydovoleb.cz' + this.$route.path;
		}
	},
  methods: {
		date,
		copyLink: function () {
			navigator.clipboard.writeText(this.path);
			this.copied = true;

				this.$store.dispatch("ge", {
					action: "copy",
					category: "share",
					label: this.$route.fullPath
				});

			setTimeout(() => this.copied = false, 1000);
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Žádost o přístup do adminu"});
  }
};
