import Vue from 'vue';

import Party from './party/do';
import Senate from './senate/do';
import Candidate from './candidate/do';

import ListCommunal from './list-communal/do';
import ListSenate from './list-senate/do';
import ListAccess from './list-access/do';
import ListRequest from './list-requests/do';
import ListParties from './list-parties/do';
import ListHeroes from './list-heroes/do';
import ListTips from './list-tips/do';
import ListReport from './list-report/do';
import ListLinks from './list-links/do';
import ListContacts from './list-contacts/do';

import Links from './links/do';
import Contacts from './contacts/do';
import Program from './program/do';
import Web from './web/do';
import Motto from './motto/do';
import Photo from './photo/do';
import About from './about/do';
import Media from './media/do';
import Logo from './logo/do';
import FAQ from './faq/do';
import FAQElement from './faq/faq-element/do';
import Color from './color/do';
import Priority from './priority/do';
import Question from './question/do';
import Generate from './generate/do';
import Support from './support/do';
import SNK from './snk/do';

Vue.component('a-party', Party);
Vue.component('a-senate', Senate);
Vue.component('a-candidate', Candidate);

Vue.component('a-list-communal', ListCommunal);
Vue.component('a-list-senate', ListSenate);
Vue.component('a-list-access', ListAccess);
Vue.component('a-list-requests', ListRequest);
Vue.component('a-list-parties', ListParties);
Vue.component('a-list-heroes', ListHeroes);
Vue.component('a-list-tips', ListTips);
Vue.component('a-list-reports', ListReport);
Vue.component('a-list-links', ListLinks);
Vue.component('a-list-contacts', ListContacts);

Vue.component('a-links', Links);
Vue.component('a-contacts', Contacts);
Vue.component('a-program', Program);
Vue.component('a-web', Web);
Vue.component('a-faq', FAQ);
Vue.component('a-faq-element', FAQElement);
Vue.component('a-motto', Motto);
Vue.component('a-photo', Photo);
Vue.component('a-about', About);
Vue.component('a-media', Media);
Vue.component('a-logo', Logo);
Vue.component('a-color', Color);
Vue.component('a-priority', Priority);
Vue.component('a-question', Question);
Vue.component('a-generate', Generate);
Vue.component('a-support', Support);
Vue.component('a-snk', SNK);
