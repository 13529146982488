import store from '@/store/store';

var months = ['ledna', 'února', 'března', 'dubna', 'května', 'června', 'července', 'srpna', 'září', 'října', 'listopadu', 'prosince'];

export function clearInput(value, fallback) {
  return value && value.length > 0 ? value.split('"').join('”').split("'").join("’").split('\\').join('') : (fallback || null)
}

export function links(arr) {
  var list = [];

  if (arr) {
    arr.forEach(item => {
      var o = {
        label: item.label || item.type,
        type: item.type,
        url: item.url,
        priority: 10
      }

      if (item.type === 'program') {
        o.label = 'Volební program';
        o.type = 'program';
        o.icon = 'program-symbol';
        o.priority = 1;
        o.color = "#134d8c";
      }

      if (item.type === 'web') {
        o.label = 'Volební web';
        o.type = 'web';
        o.priority = 3;
        o.color = "#363636";
      }

      if (item.type === 'people') {
        o.label = 'Kandidáti';
        o.type = 'people';
        o.icon = 'people-symbol';
        o.priority = 2;
        o.color = "#d72027";
      }

      if (!item.label) {
        if (item.type === 'fb') o.label = "Facebook";
        if (item.type === 'tw') o.label = "Twitter";
        if (item.type === 'ig') o.label = "Instagram";
        if (item.type === 'yt') o.label = "Youtube";
        if (item.type === 'linkedin') o.label = "LinkedIn";
        if (item.type === 'wiki') o.label = "Wikipedia";
      }

      if (o.label === 'link') o.label = stripURLintoDomain(o.url);

      list.push(o);
    });
  }

  list.sort((a, b) => a.priority - b.priority);

  return list;
}

export function csuColor(data) {
  var color = '#ccc';

  var coalition = null;

  if (data.csu.main) {
    coalition = data.csu.main.party_coalition || data.csu.main.coalition;
  }
  if (!data.csu.main) {
    coalition = data.csu.party_coalition || data.csu.coalition;
  }

  var name = data.csu.main.party_name || data.csu.main.cand_family;

  if (data) {
    if (data.custom.color.length > 0) {
      color = data.custom.color[0].color;
    } else {
      if (coalition.length === 1) {
        if (coalition[0] === 80) {
          color = createColorByName(name);
        } else {
          if (store.getters.party(coalition[0])) {
            color = store.getters.party(coalition[0]).color || createColorByName(name);
          } else {
            color = createColorByName(name);
          }
        }
      } else {
        var list = [];

        coalition.forEach(member => {
          if (member === 80 && coalition) {
            if (data.custom.support.length === 0) {
              list.push(createColorByName('_' + member));
            } else {
              list.push(data.custom.support[0].color || createColorByName(data.custom.support[0].name));
            }
          } else {
            var pc = store.getters.party(member);

            if (pc && pc.color) {
              list.push(pc.color);
            } else if (pc) {
              list.push(createColorByName(pc.name))
            } else {
              list.push(createColorByName('_' + member));
            }
          }
        });

        var grad = gradientByColors(list);

        color = grad;
      }
    }
  }

  return color;
}

export function csuLogo(data, skipCustom) {
  var empty = 'obecne/strany/loga/missing.png'
  var logo = [empty];

  if (data) {
    if (!skipCustom && data.custom.logo.length > 0) {
      logo[0] = data.custom.logo[0].url;
    } else {
      if (data.csu.main.party_coalition.length === 1) {
        if (store.getters.party(data.csu.main.party_coalition[0]) && store.getters.party(data.csu.main.party_coalition[0]).logo) {
          logo[0] = store.getters.party(data.csu.main.party_coalition[0]).logo;
        }
      } else {
        logo = [];

        data.csu.main.party_coalition.forEach(member => {
          if (member === 80) {
            if (data.custom.support.length > 0 && data.custom.support[0].logo) {
              logo.push(data.custom.support[0].logo);
            } else {
              logo.push(empty);
            }
          } else {
            if (store.getters.party(member) && store.getters.party(member).logo) {
              logo.push(store.getters.party(member).logo);
            } else {
              logo.push(empty);
            }
          }
        });
      }
    }
  }

  return logo;
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function joinWithLast (arr, comma, joiner) {
  var s = "";

  if (!comma) comma = ',';
  if (!joiner) joiner = 'a';

  if (arr.length < 2) s = arr.join("");
  if (arr.length === 2) s = arr[0] + ' ' + joiner + ' ' + arr[1];
  if (arr.length > 2) {
    s = arr[0];
    for (var i = 1; i < arr.length - 1; i++) {
      s = s + comma + ' ' + arr[i];
    }
    s = s + ' ' + joiner + ' ' + arr[arr.length - 1];
  }

  return s;
}

export function date (input, nice) {
  var x;
  if (input.split(' ').length > 1) {
    x = input.split(' ')[0].split('-');
  } else {
    x = input.split('-');
  }

  var d = [Number(x[2]) + '.'];

  if (nice) {
    d.push(months[Number(x[1]) - 1]);
  } else {
    d.push(Number(x[1]) + '.')
  }

  if (Number(x[0]) != 2022) {
    d[1] = Number(x[1]) + '.';
    d.push(x[0]);
  }

  return d.join(' ');
}

export function PDV (url) {
  return 'https://data.programydovoleb.cz/' + url.split('data/')[1];
}

export function stripURLintoDomain (url) {
  url = url.replace('https://', '');
  url = url.replace('http://', '');
  url = url.replace('www.', '');

  if (url.charAt(url.length - 1) === '/') url = url.slice(0, -1); // '12345.0'

  return url;
}

export function truncate (str, count, chars) {

  if (!str) return undefined;

  var limit = count || 20;
  var words = str.split(chars ? '' : ' ');
  var add = words.length > limit ? '...' : '';

  return words.splice(0, limit).join(chars ? '' : ' ') + add;
}

/* eslint-disable no-extend-native */
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.split(search).join(replacement);
};

export function beautifyNumber (num) {
  var str = num.toString().split('');

  if (str.length > 6) {
    str.splice(str.length - 3, 0, ' ');
    str.splice(str.length - 7, 0, ' ');
  } else if (str.length > 3) {
    str.splice(str.length - 3, 0, ' ');
  }

  return str.join('');
}

export function beautifyDate (date, uni) {
  var day = date % 100;
  var month = Math.floor(date / 100) % 100;
  var year = Math.floor(date / 10000);

  var res = day + '. ' + month + '. ' + year;

  if (uni) {
    res = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
  }

  return res;
}

export function betterURL (url) {
  var newURL = url;

  newURL = newURL.toLowerCase();
  newURL = newURL.replaceAll('.', '');
  newURL = newURL.replaceAll(',', '');
  newURL = newURL.replaceAll(';', '');
  newURL = newURL.replaceAll('?', '');
  newURL = newURL.replaceAll('!', '');
  newURL = newURL.replaceAll('(', '');
  newURL = newURL.replaceAll(')', '');
  newURL = newURL.replaceAll('á', 'a');
  newURL = newURL.replaceAll('č', 'c');
  newURL = newURL.replaceAll('ď', 'd');
  newURL = newURL.replaceAll('é', 'e');
  newURL = newURL.replaceAll('ě', 'e');
  newURL = newURL.replaceAll('í', 'i');
  newURL = newURL.replaceAll('ľ', 'l');
  newURL = newURL.replaceAll('ň', 'n');
  newURL = newURL.replaceAll('ó', 'o');
  newURL = newURL.replaceAll('ř', 'r');
  newURL = newURL.replaceAll('š', 's');
  newURL = newURL.replaceAll('ť', 't');
  newURL = newURL.replaceAll('ú', 'u');
  newURL = newURL.replaceAll('ů', 'u');
  newURL = newURL.replaceAll('ý', 'y');
  newURL = newURL.replaceAll('ž', 'z');
  newURL = newURL.replaceAll(' ', '-');
  newURL = newURL.replaceAll("'", '-');
  newURL = newURL.replaceAll('"', '-');
  newURL = newURL.replaceAll("+", '');
  newURL = newURL.replaceAll(':', '');
  newURL = newURL.replaceAll('/', '');
  newURL = newURL.replaceAll('#', '');

  var c = [];

  newURL.split('').forEach(x => {
    if ([780, 769, 778, 770, 776].indexOf(x.charCodeAt(0)) === -1) {
      c.push(x);
    }
  });

  var s = c.join('');

  return s;
}

String.prototype.hashCode = function () {
  var hash = 0;
  if (this.length === 0) {
    return hash;
  }
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

function toColor (num) {
  num >>>= 0;
  var b = num & 0xFF;
  var g = (num & 0xFF00) >>> 8;
  var r = (num & 0xFF0000) >>> 16;
  return 'rgb(' + [r, g, b].join(',') + ')';
}

export function createColorByName (name) {
  // console.log (toColor(name.hashCode()))
  return toColor(name.hashCode());
}

export function colorByData (arr, support, color, getters) {

  if (!getters) getters = store.getters;

  if (color) return color.color;

  if (arr.length > 1) {
    var list = [];

    arr.forEach(party => {
      if (party != 80) {
        list.push((getters.party(party) || {color: '#eee'}).color);
      } else {
        list.push(support ? support.color : getters.party(party).color);
      }
    })

    var gradient = gradientByColors(list);

    return gradient;
  } else {
    if (arr[0] != 80) {
      return (getters.party(arr[0]) || {color: '#eee'}).color;
    } else {
      if (color) {
        return color.color;
      } else {
        return '#eee';
      }
    }
  }
}

export function gradientByColors (arr) {
  var clr = [];

  arr.forEach((a, i) => {
    clr.push(a + ' ' + i / (arr.length - 1) * 100 + '%');
  });

  var css = 'linear-gradient(20deg, ' + clr.join(', ') + ')';

  return css;
}

export function gradient (party, getters) {
  if (party.coalition) {
    var arr = [];
    // var clr = [];

    if (!getters) getters = store.getters;

    party.coalition.forEach(reg => {
      if (typeof reg === 'number') {
        arr.push(getters.party(reg).color);
      } else if (typeof reg === 'object') {
        arr.push(reg.color || createColorByName(reg.short || reg.name));
      } else if (typeof reg === 'string') {
        arr.push(createColorByName(reg));
      } else {
        arr.push('#ccc');
      }
    });

    // arr.forEach((a, i) => {
    //   clr.push(a + ' ' + i / (arr.length - 1) * 100 + '%');
    // });
    //
    // var css = 'linear-gradient(20deg, ' + clr.join(', ') + ')';

    var css = gradientByColors(arr);

    return css;

  } else {
    return party.color;
  }
}

export function color (data, getters) {
  var party;

  if (!getters) getters = store.getters;

  if (data.member) {
    if (typeof data.member === 'number') {
      party = getters.getPartyByReg(data.member);
    }
  }

  if (((party && party.reg === 99) || !party) && data.nominee) {
    if (typeof data.nominee === 'number') {
      party = getters.getPartyByReg(data.nominee);
    }
  }

  if (party) {
    return party.color;
  } else {
    return '#aaa';
  }
}

export function getLinkType (link) {
  if (link.split('facebook.com').length > 1) return 'fb';
  if (link.split('twitter.com').length > 1) return 'tw';
  if (link.split('instagram.com').length > 1) return 'ig';
  if (link.split('wikipedia').length > 1) return 'wiki';
  if (link.split('youtube').length > 1) return 'yt';
  if (link.split('youtu').length > 1) return 'yt';
  if (link.split('hlidac').length > 1) return 'hlidac-statu';
  // if (link.split('nasi-politici').length > 1) return 'nasi-politici';
  if (link.split('demagog').length > 1) return 'demagog';
  return 'link';
}

function getLinksShortInside (link) {
  if (link.split('facebook.com').length > 1) return link.split('facebook.com/')[1];
  if (link.split('twitter.com').length > 1) return link.split('twitter.com/')[1];
  if (link.split('instagram.com').length > 1) return link.split('instagram.com/')[1];
  if (link.split('wikipedia.org/wiki').length > 1) return link.split('wikipedia.org/wiki/')[1];
  if (link.split('youtube.com').length > 1) return link.split('youtube.com/')[1];

  var dom = link.split('://')[1];

  if (dom.split('www.').length > 1) {
    return dom.split('www.')[1];
  } else {
    return dom;
  }
}

export function getLinkShort (link) {
  var l = getLinksShortInside(link);

  if (l.charAt(l.length - 1) === '/') {
    var d = l.split('/');
    d.pop();
    return d.join('/');
  } else {
    return l;
  }
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  if (s === '') return '';

  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function checkCandidateName (name) {
  var dash = name.split(' - ');
  var coma = dash[0].split(',');
  var parts = coma[0].split(' ');
  var family;

  if (parts.length > 2) {
    var second = parts.splice(1);
    if (second[0].length === 1) {
      family = second.join('');
    } else {
      family = second.join(' ');
    }

  } else {
    family = parts[1];
  }

  var case1 = parts[0].toLowerCase();
  var case2 = family ? family.toLowerCase() : '';

  return capitalize(case1) + ' ' + capitalize(case2);
}

export function colorOfParty (party, name, color) {
  // console.log(party, name, color);

  var o = {color: '#ddd'};

  if (party) {
    var p;

    if (typeof party === 'number') {
      // console.log(item.party);
      p = store.getters.party(party);

      if (party === 99) {
        o.color = '#ccc';
      } else if (p) {
        o.color = p.coalition ? gradient(p) : p.color;
      }
    }
    if (typeof party === 'string') {
      var json = JSON.parse(party);

      if (json.color) {
        if (typeof json.color === 'number') {
          p = store.getters.party(json.color);

          if (p) {
            o.color = p.color;
          }
        } else {
          o.color = json.color;
        }
      }

      if (json.coalition && json.coalition.length === 1) {
        p = store.getters.party(json.coalition[0]);

        if (p) {
          o.color = p.color;
        }
      }

      if (json.coalition && json.coalition.length > 1) {
        // console.log(gradient(json));
        o.color = gradient(json);
      }
    }
    if (typeof party === 'object') {
      json = party;

      if (json.color) {
        if (typeof json.color === 'number') {
          p = store.getters.party(json.color);

          if (p) {
            o.color = p.color;
          }
        } else {
          o.color = json.color;
        }
      }

      if (json.coalition) {
        // console.log(gradient(json));
        o.color = gradient(json);
      }
    }
  }

  // console.log(party, o.color);

  if ((!party || party === 90 || party === 80) && name) {
    o.color = createColorByName(name);
  }

  if (o.color === '#ddd' && name) {
    o.color = createColorByName(name);
  }

  if (color) {
    if (typeof color === "number") {
      p = store.getters.party(color);

      if (p) {
        o.color = p.color;
      }
    }
    if (typeof color === "string" && color.split(",").length === 1) {
      o.color = color;
    }
    if (typeof color === "string" && color.split(",").length > 1) {
      o.color = gradient({coalition: color.split(",")});
    }
  }

  return o.color;
}
