import {truncate, colorOfParty, betterURL, csuColor} from '@/common/helpers';

export default {
	name: 'senate-tiny-item',
	props: ['data', 'photo', 'links', 'motto', 'base', 'rnd2', 'both'],
	data: function () {
		return {
			buttons: [
				{type: 'fb'},
				{type: 'tw'},
				{type: 'ig'},
				{type: 'yt'},
				{type: 'linkedin'},
				{type: 'wiki'},
				{type: 'link'}
			]
		}
	},
	computed: {
		color: function () {
			return csuColor(this.data);
		},
		link: function () {
			var parts = ['/obvod'];
			var obvod = this.$store.state.static.senate.list.find(x => x.id === this.data.csu.main.obvod);

			parts.push(obvod.id + '-' + betterURL(obvod.display));
			parts.push(this.data.csu.main.hash);

			return parts.join('/');
		},
		program: function () {
			return this.links ? this.links.find(x => x.type === 'program') : null;
		},
		web: function () {
			return this.links ? this.links.find(x => x.type === 'web') : null;
		},
		supportList: function () {
			var list = [];

			if (this.data.support) {
				var json = JSON.parse('[' + this.data.support + ']');

				json.forEach(member => {
					if (typeof member === 'number') {
						list.push(this.$store.getters.party(member))
					}
					if (typeof member === 'object' && member.logo) {
						list.push(member)
					}
				})
			}

			return list;
		}
	},
	methods: {
		colorOfParty, truncate,
		support: function (data) {
			var list = [];
			var json = JSON.parse("[" + data + "]");

			json.forEach(member => {
				if (typeof member === 'number') {
					list.push(this.$store.getters.party(member))
				} else if (typeof member === 'object') {
					list.push({
						name: member.name || member.short,
						short: member.short,
						color: member.color || colorOfParty(member.short)
					})
				} else {
					list.push({
						name: member,
						short: member,
						color: colorOfParty(null, member)
					})
				}
			});

			return list;
		}
	}
};
