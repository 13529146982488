import axios from 'axios';
import {betterURL, joinWithLast, createColorByName} from '@/common/helpers';

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export default {
  name: 'layout-suggest-town',
  data: function () {
    return {
      // form: {
      num: null,
      numForm: false,
      querySearchTown: null,
      querySearchTownSearching: false,
      url: null,
      titleCustom: 0,
      title: null,
      type: 1,
      party: -1,
      partyName: null,
      partyNameCustom: false,
      partyColorCustom: false,
      partyColor: '#eeeeff',
      partyLogoCustom: false,
      partyLogo: null,
      coalitionName: null,
      coalitionNameCustom: false,
      coalitionParties: [],
      leaderName: null,
      leaderWork: null,
      note: null,
      author: null,
      pass: null,
      sent: false,
      sending: false,
      showHints: false,
      anotherAfterSave: false,
      newsTypes: {
        fb: "facebook.com",
        tw: "twitter.com",
        yt: "youtube.com",
        ig: "instagram.com",
        linkedin: "linkedin.com",
        wiki: "wikipedia.com",
        blesk: "blesk.cz",
        denik: "denik.cz",
        drbna: "drbna.cz",
        hn: "hn.cz",
        idnes: "idnes.cz",
        irozhlas: "irozhlas.cz",
        lidovky: "lidovky.cz",
        novinky: "novinky.cz",
        seznamzpravy: "seznamzpravy.cz"
      },
      parties: {
        primary: this.$store.state.static.parties.primary,
        secondary: this.$store.state.static.parties.secondary,
        full: []
      }
      // }
    }
  },
  components: {},
  computed: {
    loaded: function () {
      var test = true;

      if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
      if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
      if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

      this.parties.full = [];

      this.$store.state.dynamic.pdv.find(x => x.request === 'party/list').content.list.forEach(party => this.parties.full.push(party));

      this.parties.full.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

      return test;
    },
    querySearchTownResults: function () {
      if (!this.querySearchTown || this.loaded === false) return null;
      if (this.querySearchTown.length < 2) return null;

      var hash = betterURL(this.querySearchTown);
      var results = [];

      if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

      this.querySearchTownSearching = true;

      this.$nextTick();

      if (hash.length === 2) {
        var c;

        if (hash === 'as') c = this.$store.getters.town(554499);
        if (hash === 'es') c = this.$store.getters.town(561754);

        if (c) {
          var o = {};
          o.num = c.data[0];
          o.display = c.data[6];
          o.link = '/obec/' + o.num + '-' + betterURL(o.display);
          o.okres = c.district;

          results.push(o);
        }
      }

      if (hash.length > 2) {
        var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

        Object.keys(townlist).forEach(key => {
          // console.log(key);
          townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
            var o = {};
            o.num = item[0];
            o.display = item[6];
            o.link = '/obec/' + o.num + '-' + betterURL(o.display);
            o.okres = this.$store.getters.district(key);

            if (item[7]) {
              o.part = this.$store.getters.town(item[7]);
            }

            results.push(o);
          });
        });

      }

      results.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

      this.$nextTick();

      this.querySearchTownSearching = false;

      return results;
    },
    valid: function () {
      return isValidHttpUrl(this.url);
    },
    town: function () {
      var data;

      if (this.num) {
        data = this.$store.getters.town(this.num);
      }

      return data;
    },
		about: function () {
			if (!this.loaded) return;

			return this.$store.getters.getSource('souhrny/obce/' + this.town.district.nuts + '/' + this.town.data[0]);
		},
    demo: function () {

      var person = {};
      var party = {};
      var news = {};
      var photo = [];
      var suggestionTitlePieces = [];
      var suggestionTitle = null;
      var relates = [];

      if (this.type === 1 && this.party != -1 && this.partyNameCustom === false) {
        party.party = this.party;
        party.party2 = this.party;
      }

      if (this.type === 1 && this.party != -1 && this.partyNameCustom === true) {
        party.party2 = {
          short: this.partyName || this.$store.getters.party(this.party).name,
          coalition: [this.party],
          logo: this.$store.getters.party(this.party).logo,
          color: this.$store.getters.party(this.party).color
        };
        party.party = JSON.stringify(party.party2);
      }

      if (this.type === 2) {
        party.party2 = {short: this.coalitionNameCustom ? this.coalitionName : this.coalitionNameGenerated, coalition: this.coalitionParties};
        party.party = JSON.stringify(party.party2);
      }

      if (this.type === 3) {
        party.party2 = {short: this.partyName};
        party.party = JSON.stringify(party.party2);
      }

      party.color = this.partyColorCustom ? this.partyColor : null;
      party.logo = this.partyLogoCustom ? this.partyLogo : null;

      party.display = this.leaderName;
      party.work = this.leaderWork;

      if (this.num) {

        var town = this.town;
        var part = null;

        if (town.data[7]) part = this.$store.getters.town(town.data[7]);

        party.town_name = town.data[6];
        party.town_num = this.num;
        party.town_size = this.about ? this.about.stats.druh : 0;

        party.town_region = this.$store.state.static.regions.find(x => x.nuts === town.district.nuts.slice(0,5)).id;
        party.town_obvod = part ? part.data[0] : null;
      }

      person.display = this.name;
      person.work = this.work;
      person.party = this.party;
      person.party2 = person.party;
      person.support = this.coalitionParties.join(",");
      person.region = this.obvod > 0 ? this.obvod : 1;
      person.hash = betterURL(this.obvod + '-' + person.display);

      relates = [];

      if (this.type === 1 && this.party != -1 && this.party != 99) {
        relates.push(this.party);
      }

      news.url = this.url || "https://programydovoleb.cz";
      news.date = (new Date()).toISOString().split("T")[0];
      news.type = "primator";

      if (news.url.indexOf("http") === -1) news.url = "https://" + news.url;

      var domain = news.url.split('://')[1].split('/')[0];
      if (domain.indexOf('www.') > -1) domain = domain.split('www.')[1];

      news.outlet = domain;

      Object.keys(this.newsTypes).forEach(key => {
        if (news.url.indexOf(this.newsTypes[key]) > -1) news.outlet = key;
      });

      if (party.party2) {
        suggestionTitlePieces.push(party.town_name + ":");

        if (party.display) {
          suggestionTitlePieces.push(party.display + " bude lídrem");

        } else {
          suggestionTitlePieces.push("Do voleb půjde");
        }

        if (this.type === 1 && this.partyNameCustom === false) suggestionTitlePieces.push(this.$store.getters.party(party.party2).short + ".");
        if (this.type === 1 && this.partyNameCustom === true)  suggestionTitlePieces.push(this.$store.getters.party(this.party).short + " pod názvem \"" + party.party2.short + "\".");
        if (this.type === 2 && this.coalitionNameCustom === false ) suggestionTitlePieces.push(party.party2.short + ".");
        if (this.type === 2 && this.coalitionNameCustom === true ) suggestionTitlePieces.push("uskupení \"" + party.party2.short + "\", což je " + this.coalitionNameGenerated + ".");
        if (this.type === 3) suggestionTitlePieces.push("uskupení \"" + (party.party2.short || '') + "\".");

        if (!party.display) suggestionTitlePieces.push("Lídr zatím není znám.");

        suggestionTitle = suggestionTitlePieces.join(" ");
      }

      news.title = this.title || suggestionTitle;

      if (this.type === 2 && this.coalitionParties.length > 0) {
        this.coalitionParties.forEach(sup => {
          if (typeof sup === "number") {
            relates.push(sup);
          }
        });
      }

      if (relates.length === 0) {
        news.relates = null;
      } else {
        news.relates = relates.join(",");
      }

      party.hash = betterURL(this.num + '-' + (this.type === 1 && this.partyNameCustom === false ? this.$store.getters.party(party.party2).short : party.party2.short));
      news.hash = party.hash;

      return {
        party, person, news, photo, suggestionTitle
      }
    },
    coalitionNameGenerated: function () {
      var s = "koalice ";
      var list = [];

      this.coalitionParties.forEach(item => {
        if (typeof item === "number") {
          list.push(this.$store.getters.party(item).short)
        } else {
          list.push(item);
        }
      })

      return s + joinWithLast(list);
    }
  },
  methods: {
    betterURL,
    makeColor: function () {
      var c = "Náhoda";

      if (this.type === 3 && this.partyName && this.partyName.length > 0) {
        c = createColorByName(this.partyName);
      }

      if (this.type === 2 && this.coalitionNameGenerated) {
        c = createColorByName(this.coalitionNameGenerated);
      }

      if (this.type === 2 && this.coalitionName) {
        c = createColorByName(this.coalitionName);
      }

      if (this.type === 1 && this.partyName && this.partyName.length > 0) {
        c = createColorByName(this.partyName);
      }

      var rgb = c.split('(')[1].split(')')[0].split(',');

      this.partyColor = rgbToHex(rgb[0], rgb[1], rgb[2]).slice(0,7);

      // console.log(c, rgb, this.color);
    },
    useSuggestedTitle: function () {
      this.title = this.demo.suggestionTitle;
    },
    selectTown: function (id) {
      this.num = id;
      this.numForm = false;
    },
    addSupport: function (id) {
      var sid = id || this.$refs.support.value;

      if (!this.coalitionParties.find(x => x === sid)) {
        this.coalitionParties.push(sid);
        this.$refs.support.value = "";
      }
    },
    send: function () {
      if (this.valid && !this.sending) {
        this.sending = true;

        axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/addTown', {
          data: this.demo.party,
          news: this.demo.news,
          author: this.author,
          note: this.note,
          pass: this.$store.state.auth
        }).then(() => {
          window.scrollTo(0, 1);

          this.sending = false;
          this.sent = true;

          if (this.anotherAfterSave === true) {
            setTimeout(() => this.another(), 1500);
          } else if (this.$store.state.auth) {
            this.$router.push('/obec/' + this.town.data[0] + '-' + betterURL(this.town.data[6]) + '/' + this.demo.party.hash);
          } else {
            this.$router.push('/obec/' + this.town.data[0] + '-' + betterURL(this.town.data[6]));
          }
        });
      }
    },
    another: function () {
      this.sent = false;
      this.url  = null;
      this.note = null;
      this.title = null;
      this.leaderName = null;
      this.leaderWork = null;
      this.party = -1;
      this.type = 1;
      this.coalitionParties = [];
      this.coalitionName = null;
      this.coalitionNameCustom = false;
      this.partyName = null;
      this.partyLogoCustom = false;
      this.partyColorCustom = false;

      window.scrollTo(0, 1);
    }
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Nová strana, koalice nebo uskupení"});

    if (this.$route.query && this.$route.query.num) {
      this.num = Number(this.$route.query.num);
    } else {
      this.numForm = true;
    }
  },
  watch: {
    partyName: function () {
      this.makeColor();
    },
    coalitionName: function () {
      this.makeColor();
    }
  }
};
