import axios from 'axios';
// import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'request-access',
	props: ['hash', 'phash', 'display', 'type'],
	components: {},
	data: function () {
		return {
			name: null,
			social: null,
			email: null,
			position: null,
			sent: false,
			sending: false,
			result: null
		}
	},
	computed: {
		valid: function () {
			var res = true;

			// if (!(!this.social || this.social === '' || this.social[0] === '@' || isValidHttpUrl(this.social) || isValidHttpUrl('https://' + this.social))) {
			// 	// console.log('s');
			// 	res = false;
			// }
			// if (!this.name || this.name.length < 3) {
			// 	// console.log('n');
			// 	res = false;
			// }
			// if (!(!this.email || this.email === '' || this.email.indexOf('@') > -1)) {
			// 	// console.log('e');
			// 	res = false;
			// }

			// if (!((this.social && this.social.length > 5) || (this.email && this.email.length > 5 && this.email.indexOf('@') > -1))) {
			// 	// console.log('se');
			// 	res = false;
			// }

			return res;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/request-add', {
					hash: this.hash,
					phash: this.phash,
					name: this.name,
					type: this.type,
					social: this.social,
					email: this.email,
					link: this.$route.path,
					display: this.display,
					position: this.position,
          pass: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					if (response.data.code === 200) {
						this.$router.push('/admin-zadost/' + response.data.id);
					} else {
						this.sent = false;
						this.result = response.data.message;
					}
				});
			}
		}
	}
};
