import {beautifyNumber} from '@/common/helpers';

export default {
	name: 'CandidateStats',
	props: ['local', 'party', 'town', 'country'],
	data: function () {
		return {
			selected: null,
			selectedLast: 0
		}
	},
	computed: {
		global: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/stats/cz');
		},
		count: function () {
			if (this.local) {
				return this.local.count;
			} else {
				return this.townsum.count;
			}
		},
		results: function () {
			var obj

			if (this.local) {
				obj = {
					age: this.local.avgage,
					woman: this.local.woman,
					uni: this.local.title,
					young: this.local.y30,
					old: this.local.y65
				};
			} else {
				obj = this.townsum;
			}

			return obj;
		},
		demo: function () {
			if (this.local) {
				var obj = [
					[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
					[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
				];

				if (this.local.male && this.local.female) {
					if (this.local.male[this.local.male.length - 1] === ',') {
						this.local.male += '0';
					}
					if (this.local.female[this.local.female.length - 1] === ',') {
						this.local.female += '0';
					}

					obj[0] = JSON.parse('[' + this.local.male + ']');
					obj[1] = JSON.parse('[' + this.local.female + ']');

					// console.log(obj[0], obj[1]);
				}

				var max = 0;

				obj[0].forEach(x => max = x > max ? x : max);
				obj[1].forEach(x => max = x > max ? x : max);

				return {
					data: obj,
					max
				};
			} else if (this.townsum) {
				return this.townsum.demo;
			} else {
				return null;
			}
		},
		townsum: function () {
			var obj = {
				count: 0,
				sumage: 0,
				age: 0,
				woman: 0,
				uni: 0,
				young: 0,
				old: 0,
				demo: {
					data: [
						[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
						[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
					],
					max: 0
				}
			}

			if (this.town) {
				this.town.forEach(t => {
					obj.count += t.count;
					obj.sumage += (t.count * t.avgage);
					obj.woman += t.woman;
					obj.uni += t.title;
					obj.young += t.y30;
					obj.old += t.y65;

					var md = null;
					var fd = null;

					if (t.male[t.male.length - 1] === ',') {
						t.male += '0';
					}
					if (t.female[t.female.length - 1] === ',') {
						t.female += '0';
					}

					try {
						md = JSON.parse('[' + t.male + ']');
						fd = JSON.parse('[' + t.female + ']');
					} catch (e) {
						if (t.male[t.male.length - 1] === ',') {
							md = t.male.split(',');
							md.pop();
							md = JSON.parse('[' + md.join(',') + ']');
						}
						if (t.female[t.female.length - 1] === ',') {
							fd = t.female.split(',');
							fd.pop();
							fd = JSON.parse('[' + fd.join(',') + ']');
						}
					}

					md.forEach((x,i) => obj.demo.data[0][i] += x);
					fd.forEach((x,i) => obj.demo.data[1][i] += x);
				});

				obj.age = this.round(obj.sumage / obj.count);

				obj.demo.data[0].forEach(x => obj.demo.max = x > obj.demo.max ? x : obj.demo.max);
				obj.demo.data[1].forEach(x => obj.demo.max = x > obj.demo.max ? x : obj.demo.max);
			}

			return obj;
		}
	},
	methods: {
		beautifyNumber,
		averageAge: function (list) {
			var age = 0;
			var count = 0;

			list.forEach(person => {
				if (person.data[6]) {
					count++;
					age += person.data[6];
				}
			});

			var result = this.round(age / count);

			return result;
		},
		round: function (value) {
			return Math.round(100 * value)/100
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
	},
	mounted: function () {
		setInterval(() => {
			if (new Date().getTime() - 2500 > this.selectedLast) {
				this.selected = null;
			}
		});
	},
	watch: {
		selected: function () {
			this.selectedLast = new Date().getTime();
		}
	}
};
