import {createColorByName, betterURL, truncate} from '@/common/helpers';
import {history} from '@/components/history/helpers';
import store from '@/store/store'

function reg (party) {
	var r = party.reg;

	if (party.coal.length === 2 && party.coal.find(x => x === 80)) {
		r = !party.coal.find(x => x === 80);
	}

	return r;
}

export default {
	name: 'history-town',
	props: ['num', 'colors', 'seats', 'limit'],
	data: function () {
		return {
			width: 0,
			height: 0,
			heightMultiplicator: 2,
			highlight: {
				show: false,
				type: 0,
				selected: -1,
				sub: -1,
				list: []
			},
			old: {
				type: -1,
				selected: -1,
				sub: -1
			},
			delayClick: true,
			delay: true
		}
	},
	computed: {
		data: function () {
			if (this.delay === true || this.delayClick === true) return;

			return store.getters.pdv('history2/fetch/' + this.num);
		},
		years: function () {
			if (!this.data || !this.colors) return;

			var years = [2022, 2018, 2014, 2010, 2006];
			var list = [null, null, null, null, null];
			var largest = 0;

			var cc = [];

			if (this.data.data.seats[0] > 20) {
				this.heightMultiplicator = 1;
			}

			if (this.data.data.seats[0] > 40) {
				this.heightMultiplicator = .5;
			}

			this.data.data.seats.forEach((seat, index) => {
				if (seat != null) {

					var obj = {
						year: years[index],
						seats: seat,
						parties: this.data.data.list[index].sort((a, b) => reg(a) - reg(b)),
						candidates: this.data.data.candidates[index],
						height: this.data.data.candidates[index].length * this.heightMultiplicator + (this.data.data.list[index].length - 1) * 10,
						x: this.width - 10 - (Math.round((this.width - 20) / 4)) * index
					}

					obj.parties.forEach((party, index2) => {
						party.list = obj.candidates.filter(x => x.no === party.no);
						party.list.sort((a, b) => a.cand - b.cand);
						party.height = party.list.filter(x => x.obvod === party.obvod).length * this.heightMultiplicator;
						party.y = index2 === 0 ? 25 : obj.parties[index2 - 1].y + obj.parties[index2 - 1].list.filter(x => x.obvod === obj.parties[index2 - 1].obvod).length * this.heightMultiplicator + 10;

						if (party.obvod > 1 && index2 > 0 && obj.parties[index2 - 1].reg === party.reg) party.y -= 8;

						party.color = store.getters.party(party.reg) && store.getters.party(party.reg).color ? store.getters.party(party.reg).color : '#eee';

						if (party.coal.length === 2 && party.coal.find(x => x === 80)) {
							if (store.getters.party(party.coal.find(x => x != 80))) {
								party.color = store.getters.party(party.coal.find(x => x != 80)).color || '#eee';
							}
						}

						if (index === 0 && party.color === '#eee') {
							var c = this.colors.find(x => x.csu.main.party_no === party.no);

							if (c && c.custom.color.length > 0) {
								party.color = c.custom.color[0].color;
								cc.push({hash: betterURL(party.name), color: party.color});
							}
						}

						if (party.color === '#eee') {
							var pcc = cc.find(x => x.hash === betterURL(party.name));

							if (pcc) {
								party.color = pcc.color;
							}
						}

						if (party.color === '#eee') {
							party.color = createColorByName(betterURL(party.name));
							party.colorGenerated = true;
						}
					})

					obj.height = obj.parties[obj.parties.length - 1].y + 50;

					list[index] = obj;

					if (largest < obj.height) largest = obj.height;
				}
			})

			this.height = largest + 20;

			return list;
		},
		history: function () {
			if (!this.years) return;

			var history_data = this.data.history;

			if (typeof history_data === "number") history_data = history(this.years, (this.seats < 30), this.num);

			return history_data;
		},
		highlightList: function () {
			if (!this.history) return;

			var list = [];
			var cid = [];
			var p = null;

			if (this.highlight.type === 1 && this.highlight.selected && typeof this.highlight.selected.length != "undefined" && this.highlight.sub > -1) {
				p = this.history.find(x => x[0].index === 0 && x[0].cand.no === this.highlight.selected[0] && x[0].cand.obvod === this.highlight.selected[1] && x[0].cand.cand === this.highlight.sub);

				if (p) {
					list.push({cand: p[0].cand, history: p});
					cid.push(p[0].cand.cid);
				}
			}

			if (this.highlight.type === 2 && this.highlight.selected && typeof this.highlight.selected.length != "undefined") {
				p = this.history.filter(x => x[0].index === 0 && x[0].cand.no === this.highlight.selected[0] && x[0].cand.obvod === this.highlight.selected[1]);

				p.forEach(pp => {
					list.push({cand: pp[0].cand, history: pp});
					cid.push(pp[0].cand.cid)
				});
			}

			if (this.highlight.type === 3 && this.highlight.selected != -1 && this.highlight.sub != -1) {
				p = this.history.filter(x => x[0].index === 0);

				p.forEach(pp => {
					var y = pp.find(x => x.index === this.highlight.selected);

					if (y && typeof this.highlight.sub === 'object' && y.cand.no === this.highlight.sub[0] && y.cand.obvod === this.highlight.sub[1]) {
						list.push({cand: pp[0].cand, history: pp});
						cid.push(pp[0].cand.cid)
					}
				});
			}

			if (list.length > 0) this.$emit('update', {list, years: this.years});

			return {list, cid};
		},
		people: function () {
			if (!this.history) return;

			var list = [];

			this.history.forEach(person => {
				if (person[0].index === 0) {
					var obj = {
						color: person[0].color,
						evo: person.map(x => x.cand),
						points: [],
						index: person[0].index,
						path: null,
						// highlight: this.highlight.type > 0 && this.highlightList.find(x => x.cand === person[0].cand)
					}

					obj.cid = obj.evo[0].cid;

					for (var i = obj.index; i < 5; i++) {
						if (person.find(x => x.index === i)) {
							var year = this.years[i];
							var p = person.find(x => x.index === i);
							var cand = p.cand;

							var party = year.parties.find(x => x.no === cand.no && x.obvod === cand.obvod);

							if (party) {
								obj.points.push({x: year.x, y: party.y + cand.cand * this.heightMultiplicator - .5});
							} else {
								// obj.points.push(obj.points[obj.points.length - 1]);
								// console.log(obj.points);
							}
						} else {
							obj.points.push({x: this.years[i].x, y: this.height + 100, skip: true});
						}
					}

					var path = ["M " + obj.points[0].x + ',' + obj.points[0].y];
					var dist = Math.round((obj.points[0].x - obj.points[1].x) / 3);

					obj.points.forEach((point, index) => {
						if (index > 0 && obj.points[index - 1].x != point.x) {
							if (point.skip) {
								path.push("M " + (point.x + ',' + point.y));
							} else {
								if (obj.points[index - 1].skip) {
									path.push("M " + (point.x + ',' + point.y));
								} else {
									path.push("C " + (obj.points[index - 1].x - dist) + ',' + (obj.points[index - 1].y) + ' ' + (point.x + dist) + ',' + (point.y) + ' ' + (point.x + ',' + point.y));
								}
							}

						}
					});

					obj.path = path.join(' ');

					list.push(obj);
				}
			});

			return list;
		},
		viewBox: function () {
			return '0 0 ' + this.width + ' ' + this.height;
		}
	},
	methods: {
		setWidth: function () {
			this.width = this.$el.offsetWidth;
		},
		click_setLimit: function (year, no) {
			if (!this.limit) {
				this.highlight.type = year === 0 ? 2 : 3;
				setTimeout(() => {
					if (year === 0) {
						this.highlight.selected = no;
					} else {
						this.highlight.selected = year;
						setTimeout(() => this.highlight.sub = no, 200);
					}

				}, 100);
			}
		},
		personname: function (data) {
			return [data.cand.name[2],data.cand.name[0],data.cand.name[1],data.cand.name[3]]
		},
		truncate
	},
	mounted: function () {
		this.setWidth();
		this.height = 2000;

		if (this.seats < 40) {
			this.delayClick = false;
		}

		window.addEventListener('resize', () => this.setWidth());

		setTimeout(() => this.delay = false, 2000);

		if (this.limit) {
			this.highlight.type = this.limit[0];
			setTimeout(() => this.highlight.selected = this.limit[1], 100);
			setTimeout(() => this.highlight.sub = this.limit[2], 200);
		}
	},
	watch: {
		highlight: {
			handler: function () {
				// console.log(this.old.type, this.highlight.type, '-', this.old.selected, this.highlight.selected, '-', this.old.sub, this.highlight.sub);

				if (this.old.type != this.highlight.type) {
					this.highlight.selected = -1;
					this.highlight.sub = -1;
					this.old.selected = -1;
					this.old.sub = -1;
					this.old.type = this.highlight.type;
				} else if (this.old.selected != this.highlight.selected) {
					this.highlight.sub = -1;
					this.old.sub = -1;
					this.old.selected = this.highlight.selected;
				}
			},
			deep: true
		},
		limit: {
			handler: function () {
				this.highlight.type = this.limit[0];
				setTimeout(() => this.highlight.selected = this.limit[1], 100);
				setTimeout(() => this.highlight.sub = this.limit[2], 200);
			},
			deep: true
		}
	}
};
