import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'admin-media',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			list: [],
			url: '',
			label: null,
			typeSelected: null,
			typeSocial: 0,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url) && this.label && this.label.length > 4;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		isValidHttpUrl,
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/media-add', {
					hash: this.hash,
					phash: this.phash,
					source: encodeURIComponent(this.url),
					label: this.label ? this.label.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
          token: this.token
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						url: this.url,
						hash: this.hash,
						phash: this.phash,
						label: this.label,
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/media-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.label = null;
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				this.list.push({
					url: src.source,
					hash: src.hash,
					label: src.title,
					id: src.id,
					removed: false
				});
			})
		}
	}
};
