import copy from 'copy-to-clipboard';
import {betterURL} from "@/common/helpers";

export default {
	name: 'admin-list-senate',
	props: ['csu', 'access'],
	data: function () {
		return {
			limit: 30,
			querySearchTown: '',
			copied: false
		}
	},
	methods: {
		betterURL,
		tokenLink: function (hash) {
			return 'https://2022.programydovoleb.cz/admin/' + this.access.find(x => x.hash === hash).token;
		},
		copyLink: function (path) {
			copy(path);
			this.copied = true;

				this.$store.dispatch("ge", {
					action: "copy",
					category: "share",
					label: this.$route.fullPath
				});

			setTimeout(() => this.copied = false, 2000);
		}
	}
};
