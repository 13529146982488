import {betterURL} from '@/common/helpers'

export default {
	name: 'admin-list-access',
	props: ['hash', 'towns', 'senate', 'limited', 'type'],
	data: function () {
		return {
			limit: [],
			nutsused: [],
			querySearchTown: ''
		}
	},
	computed: {
		loaded: function () {
			return true;
		},
		numnuts: function () {
			var data = this.$store.getters.getSource('volby/kom/2022/static/cnumnuts');

			if (data) {
				data.okresy.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return data;
		},
		hierarchy: function () {
			return this.$store.getters.pdv('town/hierarchy');
		},
		obce: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/obce-flat');
		},
		token: function () {
			return this.$route.path.split('/').pop();
		},
		available: function () {
			var towns = [];
			var senate = [];

			// komunal

			if (this.limited) {
				this.limited.forEach(item => {
					if (item > 100000) {
						towns.push(item);
					}
				})
			} else if (this.towns) {
				this.towns.forEach(item => {
					if (towns.indexOf(item.num) === -1) towns.push(item.num);
				})
			}

			// senat

			if (this.limited) {
				this.$store.state.static.senate.list.forEach(item => {
					if (this.limited.indexOf(item.id) > -1) senate.push(item);
				});
			} else if (this.senate) {
				this.$store.state.static.senate.list.forEach(item => {
					if (this.senate.find(x => x.obvod === item.id)) senate.push(item);
				});
			}

			return {towns, senate}
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			// console.log(hash);

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.$store.getters.town(554499);
				if (hash === 'es') c = this.$store.getters.town(561754);

				if (c) {
					var o = {};
					o.num = c.data[0];
					o.display = c.data[6];
					o.link = '/obec/' + o.num + '-' + betterURL(o.display);
					o.okres = c.district;

					results.push(o);
				}
			}

			if (hash.length > 2) {
				var townlist = this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat').content.list;

				Object.keys(townlist).forEach(key => {
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						var o = {};
						o.num = item[0];
						o.display = item[6];
						o.link = 'obec/' + o.num + '-' + betterURL(o.display);
						o.okres = this.$store.getters.district(key);
						o.size = item[4];

						if (item[7]) {
							o.part = this.$store.getters.town(item[7]);
						}

						results.push(o);
					});
				});

			}

			results.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		}
	},
	methods: {
		nuts: function (nuts, action) {

			this.hierarchy.list[(nuts.length === 5 ? 'kraje' : 'okresy')][nuts].forEach(x => {
				if (action === -1) {
					if (this.limit.find(y => y === x)) this.limit.splice(this.limit.findIndex(y => y === x), 1);
					this.nutsused.splice(this.nutsused.findIndex(y => y === nuts), 1);
				}
				if (action === 1) {
					if (this.available.towns.find(y => y === x)) this.limit.push(x);
					this.nutsused.push(nuts);
				}
			});
		},
		all: function (list, action, senate) {
			if (senate) {
				if (action === 1) {
					list.forEach(item => {
						if (this.limit.indexOf(item.obvod) === -1) {
							this.limit.push(item.obvod);
						}
					})
				}

				if (action === 2) {
					list.forEach(item => {
						if (this.limit.indexOf(item.obvod) > -1) {
							this.limit.splice(this.limit.indexOf(item.obvod), 1);
						}
					})
				}
			} else {
				if (action === 1) {
					list.forEach(item => {
						if (this.limit.indexOf(item.town.data[0]) === -1) {
							this.limit.push(item.town.data[0]);
						}
					})
				}

				if (action === 2) {
					list.forEach(item => {
						if (this.limit.indexOf(item.town.data[0]) > -1) {
							this.limit.splice(this.limit.indexOf(item.town.data[0]), 1);
						}
					})
				}
			}
		}
	}
};
