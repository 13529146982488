import {links, truncate, stripURLintoDomain, csuLogo, csuColor, betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType, isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-election-party',
	props: ['townHash','hash'],
	data: function () {
		return {
			candlimit: true,
			candlimit_count: 7
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		analytics: function () {
			return this.$store.getters.pdv('system/analytics/obec*' + this.townHash + '*' + this.hash);
		},
		data: function () {
			var loaded = this.$store.getters.pdv('detail/party/' + this.hash);
			return loaded ? loaded.data : undefined;
		},
		town: function () {
			return this.$store.getters.town(Number(this.townHash.split('-')[0]));
		},
		name: function () {
			if (this.data) {
				this.$store.dispatch("ga", {title: this.data.csu.main.party_name + ", " + this.town.data[6] + " v komunálních volbách 2022"});

				setTimeout(() => {
					if (location.hash != '') {
						this.$el.querySelector("a[name=" + location.hash.split('#')[1] + "]").scrollIntoView({behavior: "smooth", block: "center"});
					}
				}, 500);

				this.candlimit_count = this.data.csu.candidates.length < 12 ? 12 : 7;

				return this.data.csu.main.party_name;
			} else {
				return null;
			}
		},
		color: function () {
			return this.data ? csuColor(this.data) : null;
		},
		logo: function () {
			return this.data ? csuLogo(this.data) : null;
		},
		logoAll: function () {
			return this.data ? csuLogo(this.data, true) : null;
		},
		links: function () {
			return this.data && this.data.custom.links ? links(this.data.custom.links) : [];
		},
		contacts: function () {
			return this.data && this.data.custom.contacts ? this.data.custom.contacts : [];
		},
		program: function () {
			return this.links ? this.links.find(x => x.type === 'program') : null;
		},
		web: function () {
			return this.links ? this.links.find(x => x.type === 'web') : null;
		},
		leader: function () {
			var data = this.data && this.data.csu.candidates && this.data.csu.candidates[0] ? this.data.csu.candidates[0] : null;
			var item = null;

			if (data) {
				item = {
					name: [data[4], data[2], data[3], data[5]],
					work: data[8],
					about: this.data.custom.candidates.about.find(x => x.phash === data[1]),
					photo: this.data.custom.candidates.photos.find(x => x.phash === data[1]),
					links: this.data.custom.candidates.links.filter(x => x.phash === data[1]),
					motto: this.data.custom.candidates.motto.filter(x => x.phash === data[1]),
					data
				}
			}

			return item;
		},
		obvody: function () {
			var list = {
				count: 1,
				list: [[],[],[],[],[],[],[],[],[],[]]
			};

			if (this.data && this.data.others.find(x => x.csu.main.obvod)) {
				this.data.others.forEach(x => {
					if (x.csu.main.obvod > list.count) list.count = x.csu.main.obvod;
					list.list[x.csu.main.obvod - 1].push(x);
				})
			}

			return list;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL, beautifyNumber, truncate, date, gradient, getLinkType, beautifyDate, colorOfParty, isValidHttpUrl, stripURLintoDomain, csuColor,
		domain: function (url) {
			return stripURLintoDomain(url).split('/')[0]
		},
		mediaicon: function (url) {
			var outlets = ['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'];
			var link = this.$store.state.root + 'static/icon/link.svg';

			outlets.forEach(o => {
				if (url.split(o).length > 1) link = this.$store.state.root + 'static/icon-media/' + o + '.jpg';
			})

			return link;
		},
		hl: function () {
			return encodeURIComponent(this.town.data[6]);
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
		setTimeout(() => {
			this.$refs.anchors.trigger(location.hash);
		}, 1500);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		},
		$route: function () {
			window.scrollTo(0, 1);
		}
	}
};
