export default {
	name: 'layout-voting',
	data: function () {
		return {
			model: []
		}
	},
	computed: {
		test: function () {
			var valid = true;
			var used = 0;
			var partyCrossed = 0;
			var partyCrossedLength = 0;
			var reduced = 0;
			var votes = 0;
			var same = false;

			this.model.forEach(party => {
				if (party.selected) {
					partyCrossed++;
					partyCrossedLength = party.list.length;
					if (party.list.filter(x => x.selected).length > 0) same = true;
				} else {
					used += party.list.filter(x => x.selected).length;
				}
			});

			if (partyCrossed > 1) valid = false;
			if (partyCrossed === 1 && used > 7) valid = false;
			if (partyCrossed === 0 && used > 7) valid = false;
			if (partyCrossed === 0 && used === 0) valid = false;

			if (partyCrossed === 0) {
				votes = used;
			} else if (partyCrossed === 1) {
				votes = used + partyCrossedLength;
				if (votes > 7) votes = 7;
				reduced = 7 - used;
			}

			return {valid, used, votes, reduced, partyCrossedLength, partyCrossed, same};
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
		this.$store.dispatch("ga", {title: "Jak volit v komunálních a senátních volbách 2022"});

		var parties = ['Naše Veselá', 'SNK Hasiči', 'Jsme Veselí', 'Za Veselou', 'Veselá jede', 'Stop Smutné'];
		var names = ['Aneta', 'Bedřich', 'Ctirad', 'David', 'Eva', 'Filip', 'Gustáv', 'Hugo', 'Ivana', 'Jitka', 'Karel', 'Lenka', 'Milan', 'Norbert', 'Ondra', 'Petr', 'Quido', 'Richard', 'Stanislava', 'Tomáš', 'Uršula', 'Viktorie', 'Xaver', 'Zdeňka']

		parties.forEach(party => {
			var o = {
				name: party,
				selected: false,
				list: []
			}

			var count = Math.floor(Math.random() * 4) + 4;

			for (var i = 0, n; i < count; i++) {
				while (o.list.length === i) {
					n = names[Math.floor(Math.random() * names.length)];

					if (!o.list.find(x => x.name === n)) {
						o.list.push({selected: false, name: n});
					}
				}
			}

			this.model.push(o);
		})
  }
};
