import axios from 'axios';

export default {
	name: 'admin-photo',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			url: null,
			tick: 0
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		updatePhoto: function (file) {
			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/photo-update', {
				hash: this.hash,
				phash: this.phash,
				url: 'https://data.programydovoleb.cz/' + file.link,
				token: this.token
			}).then(() => {
				this.url = 'https://data.programydovoleb.cz/' + file.link;
				this.tick++;
			});
		},
		remove: function () {
			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/photo-remove', {
				hash: this.hash,
				phash: this.phash,
				token: this.token
			}).then(() => {
				this.url = null;
				this.tick++;
			});
		}
	},
	mounted: function () {
		if (this.data && this.data[0]) {
			this.url = this.data[0].url;
		}
	}
};
