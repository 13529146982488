import {truncate, links, colorOfParty, betterURL, csuColor} from '@/common/helpers';

export default {
	name: 'senate-list-item',
	props: ['data'],
	data: function () {
		return {
			buttons: [
				{type: 'program', label: 'Program'},
				{type: 'web', label: 'Web'},
				{type: 'fb'},
				{type: 'tw'},
				{type: 'ig'},
				{type: 'yt'},
				{type: 'linkedin'},
				{type: 'wiki'},
				// {type: 'link'}
			]
		}
	},
	computed: {
		color: function () {
			return csuColor(this.data);
		},
		link: function () {
			var parts = ['/obvod'];
			var obvod = this.$store.state.static.senate.list.find(x => x.id === this.data.csu.main.obvod);

			parts.push(obvod.id + '-' + betterURL(obvod.display));
			parts.push(this.data.csu.main.hash);

			return parts.join('/');
		},
		photo: function () {
			var photo = null;

			if (this.data.custom.photo.length > 0) {
				photo = this.data.custom.photo[0].url;
			} else {
				if (this.data.csu.main.coalition.length > 1) {
					//
				} else {
					if (this.data.csu.main.member != 99) {
						photo = this.$store.getters.party(this.data.csu.main.member).logo;
					} else if (this.data.csu.main.nominee != 80) {
						photo = this.$store.getters.party(this.data.csu.main.nominee).logo;
					} else if (this.data.csu.main.coalition.length === 1) {
						photo = '/static/missing.png';
					}
				}
			}

			return photo;
		},
		person: function () {
			return this.data.csu.main
		},
		links: function () {
			return this.data && this.data.custom.links ? links(this.data.custom.links) : [];
		},
		program: function () {
			return this.links ? this.links.find(x => x.type === 'program') : null;
		},
		web: function () {
			return this.links ? this.links.find(x => x.type === 'web') : null;
		},
		supportList: function () {
			var list = [];

			if (this.data.support) {
				var json = JSON.parse('[' + this.data.support + ']');

				json.forEach(member => {
					if (typeof member === 'number') {
						list.push(this.$store.getters.party(member))
					}
					if (typeof member === 'object' && member.logo) {
						list.push(member)
					}
				})
			}

			return list;
		}
	},
	methods: {
		colorOfParty, truncate,
		support: function (data) {
			var list = [];
			var json = JSON.parse("[" + data + "]");

			json.forEach(member => {
				if (typeof member === 'number') {
					list.push(this.$store.getters.party(member))
				} else if (typeof member === 'object') {
					list.push({
						name: member.name || member.short,
						short: member.short,
						color: member.color || colorOfParty(member.short)
					})
				} else {
					list.push({
						name: member,
						short: member,
						color: colorOfParty(null, member)
					})
				}
			});

			return list;
		}
	}
};
