import axios from 'axios';

export default {
	name: 'admin-contacts',
	props: ['hash', 'phash', 'data', 'type', 'open', 'senate', 'noRemove'],
	data: function () {
		return {
			list: [],
			value: '',
			label: null,
			typeSelected: 'text',
			typeOptions: [
				{id: 'text', value: 'obecný text'},
				{id: 'phone', value: 'Telefon'},
				{id: 'email', value: 'E-mail'},
				{id: 'whatsapp', value: 'WhatsApp'},
				{id: 'telegram', value: 'Telegram'},
				{id: 'address', value: 'Adresa'}
			],
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return this.value.length > 5;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/contact-add', {
					hash: this.hash,
					phash: this.phash,
					type: this.typeSelected,
					value: encodeURIComponent(this.value),
					label: this.label ? this.label.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
          token: this.token
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						value: this.value,
						type: this.typeSelected,
						hash: this.hash,
						phash: this.phash,
						label: this.label,
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/contact-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.value  = '';
			this.label = '';
			this.typeSelected = 'text';
		}
	},
	mounted: function () {
		if (this.type) {
			this.typeSelected = this.type || 'text';
		}
		if (this.data) {
			this.data.forEach(src => {
				// if (src.type != 'program') {
				this.list.push({
					value: src.value,
					type: src.type,
					hash: src.hash,
					phash: src.phash,
					label: src.label,
					id: src.id,
					removed: false
				});
				// }
			})
		}
	}
};
