import {beautifyNumber, betterURL, date, truncate, getLinkType} from "@/common/helpers";

export default {
	name: 'PersonSummary',
	props: ['data', 'href', 'town', 'hash', 'phash', 'color'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		leader: function () {
			return this.data
		},
		links: function () {
			var list = [];

			var prep = [];

			this.data.links.forEach(link => {
				prep.push({
					href: link.url,
					type: link.type
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (wiki) list.push(wiki);

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		truncate,
		getLinkType
	}
};
