import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'tip-form',
	props: ['id', 'hint', 'type'],
	components: {},
	data: function () {
		return {
			url: '',
			note: '',
			author: null,
			sent: false,
			sending: false,
			showHints: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/add/' + this.type, {
					type: this.type,
					source: encodeURIComponent(this.url),
					note: encodeURIComponent((this.hint + ': ' || '') + this.note),
					author: this.author ? encodeURIComponent(this.author) : null
				}).then(() => {
					this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 1500);
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.note = '';
		}
	}
};
