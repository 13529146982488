import axios from 'axios';
import {betterURL, truncate} from '@/common/helpers'

export default {
	name: 'admin',
	props:['token'],
	data: function () {
		return {
			tkn: null,
			pass: null,
			tick: 1
		}
	},
  components: {},
	computed: {
		data: function () {
			return this.tick + 1 > 0 && this.token && (this.token.length > 10 || this.$store.state.auth) ? this.$store.getters.pdv('admin/init/' + this.token) : undefined;
		},
		loaded: function () {
			return this.data && this.data.code === 200
		},
		csu: function () {
			return this.loaded ? this.data.data.csu : undefined;
		},
		custom: function () {
			return this.loaded ? this.data.data.custom : undefined;
		},
		candidate: function () {
			return this.loaded && this.data.data.type === 3 ? this.csu.candidates.find(x => x[1] === this.data.data.phash) : undefined;
		},
		town: function () {
			return this.loaded && (this.data.data.type === 1 || this.data.data.type === 3) ? this.$store.getters.town(this.csu.main.num) : undefined;
		},
		obvod: function () {
			return this.loaded && this.data.data.type === 2 ? this.$store.getters.obvod(this.csu.main.obvod) : undefined;
		},
		party: function () {
			return this.loaded && this.data.data.type === 4 ? this.$store.getters.party(this.csu.reg) : null;
		},
		sitemap: function () {
			return null;
			// if (this.data && this.data.data.type === 6) {
			// 	var list = [];
			//
			// 	this.$store.getters.parties().forEach(town => {
			// 		list.push("<url><loc>https://2022.programydovoleb.cz/strana/" + town.hash + "</loc><lastmod>2022-08-12</lastmod><changefreq>daily</changefreq></url>");
			// 	});
			//
			// 	return list.join("\n");
			// } else {
			// 	return undefined;
			// }
		},
		logo: function () {
			if (this.custom && this.custom.logo && this.custom.logo.length > 0) {
				return this.custom.logo[0].url;
			}

			if (this.party && this.party.logo) return 'https://data.programydovoleb.cz/' + this.party.logo;

			if (this.loaded && (this.data.data.type === 1 || this.data.data.type === 3)) {
				if (this.custom.logo[0]) return this.custom.logo[0].url;

				var party = this.$store.getters.party(this.csu.main.party_reg);

				if (party && party.logo) return 'https://data.programydovoleb.cz/' + party.logo;
			}

			if (this.loaded && (this.data.data.type === 2)) {
				if (this.custom.photo[0]) return this.custom.photo[0].url;
			}

			return null;
		},
		headline: function () {
			var title = "načítá se";

			if (this.loaded) {
				if (this.data.data.type === 1) {
					title = truncate(this.csu.main.party_name, 10); // .length < 48 ? this.csu.main.party_name : this.csu.main.party_name;
				}
				if (this.data.data.type === 2) {
					title = this.csu.main.cand_name + ' ' + this.csu.main.cand_family;
				}
				if (this.data.data.type === 3) {
					title = this.candidate[2] + ' ' + this.candidate[3];
				}
				if (this.data.data.type === 4) {
					title = truncate(this.party.name, 10);
				}
				if (this.data.data.type === 6) {
					title = 'ROOT';
				}
			}

			if (this.data && this.data.code > 200) {
				title = 'Chyba';
			}

			this.$store.dispatch('ga', {title: 'ADMIN'});

			return title;
		}
	},
  methods: {
		betterURL,
		rePDV: function () {
			this.$store.state.dynamic.pdv.find(x => x.request === 'admin/init/' + this.token).request += "-" + new Date().getTime();
			this.tick++;
		},
		authme: function () {

			axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/login', {
				pass: this.pass
			}).then((response) => {
				if (response.data.result === 1) {
					this.$store.commit('auth', this.pass);
				}
			});
		}
	},
  mounted: function () {
		window.scrollTo(0, 0);
	}
};
