export default {
	name: 'vote-element',
	props: ['hash', 'qid', 'question', 'answers', 'type', 'limit', 'headline', 'values', 'colors'],
	components: {},
	data: function () {
		return {
			voted: null,
			today: false
		}
	},
	computed: {
		town: function () {
			return this.hash.split('-')[0].length > 5
		},
		status: function () {
			var stat = 1;

			if (this.voted != null && this.today === true) {
				stat = 2;
			} else if (this.$store.state.voting.votes.find(x => x.hash === this.hash && x.qid === this.qid)) {
				stat = 4;
			}
			//  else if (this.town && this.$store.state.voting.home.town != this.hash && this.$store.state.voting.home.town != null) {
			// 	stat = 3;
			// } else if (!this.town && this.$store.state.voting.home.obvod != this.hash && this.$store.state.voting.home.obvod != null) {
			// 	stat = 3;
			// }

			return stat;
		},
		cast: function () {
			return this.$store.state.voting.votes.find(x => x.hash === this.hash && x.qid === this.qid)
		},
		casted: function () {
			return this.cast ? Math.round(new Date(this.cast.voted).getTime()/1000) : null;
		},
		data: function () {
			return this.$store.getters.pdv('voting-results/fetch/' + this.hash + '___' + this.qid);
		},
		results: function () {
			var arr = [];

			if (this.data) {
				Object.keys(this.data.values).forEach(key => {
					arr.push({
						id: Number(key.split('_')[1]),
						value: this.data.values[key]
					});
				});
			}

			return arr;
		}
	},
	methods: {
		vote: function (id) {
			if (this.voted) return;

			this.voted = id;
			this.today = true;

			this.$store.dispatch("vote", {
				hash: this.hash,
				qid: this.qid,
				value: id
			})
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
	},
	mounted: function () {
		var vote = this.cast;

		if (vote) this.voted = vote.value;
	}
};
