import regions from './db/commons/regions';
import selected from './db/commons/selected';

import clubs from './db/senate/clubs';
import current from './db/senate/current';
import list from './db/senate/list';
import svolby from './db/senate/volby';

const state = {
  static: {
    regions,
    commons: {
      selected
    },
    senate: {
      clubs,
      list,
      current,
      volby: svolby
    },
    parties: {
      primary: [768, 7, 1, 47, 53, 720, 1114, 166, 721],
      secondary: [1245, 1187, 703, 724, 714, 1227, 5]
    }
  },
  dynamic: {
    partyList: [],
    source: [],
    pdv: []
  },
  voting: {
    token: null,
    home: {
      town: null,
      obvod: null
    },
    votes: []
  },
  personal: {
    preferredView: 'result',
    preferredViewLimit: 5
  },
  auth: null,
  server: 'https://data.programydovoleb.cz/',
  root: 'https://2022.programydovoleb.cz/',
  api: 'https://2022.programydovoleb.cz/lib/app/api.php?action=',
  tick: 0,
  start: new Date().getTime(),
  cache: Math.floor(new Date().getTime() / 1000 / 60 / 60)
};

export default state;
