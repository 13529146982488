import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'admin-question',
	props: ['hash', 'phash', 'data', 'open', 'question'],
	data: function () {
		return {
			content: null,
			yesno: null,
			storedContent: null,
			storedYesno: null,
			id: null,
			url: null,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false,
			quicks: ['bez odpovědi', 'ano', 'ne', 'je to složitější']
		}
	},
	computed: {
		valid: function () {
			return this.url && isValidHttpUrl(this.url) || !this.url;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/answer-update', {
					hash: this.hash,
					question: this.question.question,
					content: this.content ? this.content.replace(/["]/g, "“").replace(/[']/g, "‘") : null,
					url: this.url,
					yesno: this.yesno || 9,
          token: this.token
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.storedContent = this.content;
					this.storedYesno = this.yesno;

					this.id = response.data.id;

					setTimeout(() => {this.sent = false}, 2500);
				});
			}
		},
		remove: function () {
			axios.post(this.$store.state.api + 'admin/answer-remove', {
				hash: this.hash,
				phash: this.phash,
				question: this.question.question,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					this.storedYesno  = null;
					this.storedContent = null;
					this.yesno  = null;
					this.url = null;
					this.content = null;
					this.id = null;

					if (this.question.yesno === 1) {
						this.yesno = 0;
						this.storedYesno = 0;
					}
				}
			})
		}
	},
	mounted: function () {
		if (this.question.yesno === 1) {
			this.yesno = 0;
			this.storedYesno = 0;
		}
		if (this.data) {
			this.data.forEach(src => {
				this.storedContent = src.answer;
				this.content = src.answer;
				this.storedYesno = src.yesno;
				this.yesno = src.yesno < 9 ? src.yesno : null;
				this.id = src.id;
				this.url = src.url;
			})
		}
	}
};
