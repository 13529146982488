export default {
	name: 'layout-homepage',
	props: ['id'],
	data: function () {
		return {
			parties: [
				{reg: 768, value: 1684, selected: null},
				{reg: 7, value: 1946, selected: null},
				{reg: 1, value: 3646, selected: null},
				{reg: 47, value: 1471, selected: null},
				{reg: 53, value: 2600, selected: null},
				{reg: 720, value: 358, selected: null},
				{reg: 703, value: 148, selected: null},
				{reg: 1114, value: 141, selected: null},
				{reg: 166, value: 2601, selected: null},
				{reg: 714, value: 84, selected: null},
				{reg: 721, value: 484, selected: null},
				{reg: 5, value: 186, selected: null},
			],
			labels: [
				'bez odpovědi',
				'drtivé zhoršení',
				'výrazné zhoršení',
				'zhoršení',
				'mírné zhoršení',
				'podobně jako minule',
				'mírné zlepšení',
				'zlepšení',
				'výrazné zlepšení',
				'dramatické zlepšení',
				'ještě více'
			]
		}
	},
  components: {},
	computed: {
		common: function () {
			return this.$store.getters.pdv('prognostic/common');
		},
		data: function () {
			var d = this.$store.getters.pdv('prognostic/fetch/' + this.id);

			if (d) {
				Object.keys(d.data).forEach(key => {
					if (d.data[key] && key.split('_')[1]) {
						this.parties.find(x => x.reg === Number(key.split('_')[1])).selected = d.data[key];
					}
				});
			}

			return d;
		},
		options: function () {
			var list = [];

			this.parties.forEach((party) => {
				var o = {
					party,
					limits: [0]
				}

				o.limits.push(Math.round(party.value * .15));
				o.limits.push(Math.round(party.value * .4));
				o.limits.push(Math.round(party.value * .75));
				o.limits.push(Math.round(party.value * .96));

				o.limits.push(Math.round(party.value * 1.05));
				o.limits.push(Math.round(party.value * 1.33));
				o.limits.push(Math.round(party.value * 1.8));
				o.limits.push(Math.round(party.value * 2.5));
				o.limits.push(Math.round(party.value * 5));

				o.limits.push(25000);

				list.push(o);
			});

			return list;
		},
	},
  methods: {
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Prognostická hra, ticket " + this.id});
  }
};
