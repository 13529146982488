import axios from 'axios';

export default {
	name: 'link-add',
	props: ['hash', 'name', 'phash'],
	components: {},
	data: function () {
		return {
			value: '',
			label: null,
			typeSelected: 'text',
			typeOptions: [
				{id: 'text', value: 'obecný text'},
				{id: 'phone', value: 'Telefon'},
				{id: 'email', value: 'E-mail'},
				{id: 'whatsapp', value: 'WhatsApp'},
				{id: 'telegram', value: 'Telegram'},
				{id: 'address', value: 'Adresa'}
			],
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return this.value.length > 5;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/addContact', {
					hash: this.hash,
					phash: this.phash,
					value: encodeURIComponent(this.value),
					type: this.typeSelected,
					label: this.label,
          pass: this.$store.state.auth
				}).then(() => {
					this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 1500);
				});
			}
		},
		another: function () {
			this.sent = false;
			this.value  = '';
			this.label = null;
			this.typeSelected = 'text';
		}
	},
	mounted: function () {
		if (this.type) {
			this.typeSelected = 'text';
		}
	}
};
