import {truncate, csuColor, csuLogo, links, betterURL} from '@/common/helpers';

export default {
	name: 'leader-list-item',
	props: ['data'],
	data: function () {
		return {
			buttons: [
				{type: 'fb'},
				{type: 'tw'},
				{type: 'ig'},
				{type: 'yt'},
				{type: 'wiki'}
			]
		}
	},
	computed: {
		color: function () {
			return csuColor(this.data); // colorOfParty(this.data.party, (typeof this.data.party === 'string' ? JSON.parse(this.data.party).short : 'xxx'), this.data.color)
		},
		logo: function () {
			return csuLogo(this.data)
		},
		logoAll: function () {
			return this.data ? csuLogo(this.data, true) : null;
		},
		link: function () {
			var parts = ['/obec'];
			var obec = this.$store.getters.town(this.data.csu.main.num);

			parts.push(obec.hash);
			parts.push(this.data.csu.main.hash);

			return parts.join('/');
		},
		links: function () {
			return this.data && this.data.custom.links ? links(this.data.custom.links) : [];
		},
		web: function () {
			return this.links ? this.links.find(x => x.type === 'web') : null;
		},
		program: function () {
			return this.links ? this.links.find(x => x.type === 'program') : null;
		},
		list: function () {
			return this.data.csu.candidates;
		}
	},
	methods: {
		csuColor, csuLogo, truncate, betterURL,
		name: function (csu) {
			return [
				csu[4],
				csu[2],
				csu[3],
				csu[5]
			]
		}
	}
};
