import axios from 'axios';

export default {
	name: 'person-photo',
	props: ['data', 'ratio', 'hash', 'width', 'phash', 'admin'],
	data: function () {
		return {
			tick: 1
		}
	},
	computed: {
		src: function () {
			var link;

			if (this.tick && this.data.photo && this.data.photo.url) {
				link = this.data.photo.url;
			} else {
				link = '/static/missing.png';
			}

			return link;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		updatePhoto: function (file) {

			if (this.admin) {
				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=admin/photo-update', {
					hash: this.hash,
					phash: this.phash,
					url: 'https://data.programydovoleb.cz/' + file.link,
					token: this.token
				}).then(() => {
					this.$refs.myModal.opened = false;
					this.data.photo = {url: 'https://data.programydovoleb.cz/' + file.link};
					this.tick++;
				});
			} else {
				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=tip/photoUpdate', {
					hash: this.hash,
					phash: this.phash,
					url: 'https://data.programydovoleb.cz/' + file.link,
					pass: this.$store.state.auth
				}).then(() => {
					this.$refs.myModal.opened = false;
					this.data.photo = {url: 'https://data.programydovoleb.cz/' + file.link};
					this.tick++;
				});
			}
		}
	}
};
