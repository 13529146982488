import {betterURL, date, colorOfParty} from '@/common/helpers';

export default {
	name: 'layout-homepage-senate',
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
  components: {},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		tipsSenate: function () {
			return this.$store.getters.pdv('senate/all', 2022);
		},
		tipsSenateStats: function () {
			var stats = {
				parties: [],
				members: []
			}

			if (this.tipsSenate) {
				this.tipsSenate.list.forEach(person => {
					person.csu.main.coalition.forEach(member => {
						var obj = stats.parties.find(x => x.reg === member);

						if (!obj) {
							obj = {
								reg: member,
								party: this.$store.getters.party(member),
								value: 0
							}

							stats.parties.push(obj);
						}

						obj.value++;
					});

					var obj2 = stats.members.find(x => x.reg === person.csu.main.member);

					if (!obj2) {
						obj2 = {
							reg: person.csu.main.member,
							party: this.$store.getters.party(person.csu.main.member),
							value: 0
						}

						if (obj2.reg != 99) stats.members.push(obj2);
					}

					obj2.value++;
				});
			}

			stats.parties.sort((a, b) => (a.party ? a.party.short : '').localeCompare((b.party ? b.party.short : ''), 'cs'));
			stats.parties.sort((a, b) => b.value - a.value);

			stats.members.sort((a, b) => (a.party ? a.party.short : '').localeCompare((b.party ? b.party.short : ''), 'cs'));
			stats.members.sort((a, b) => b.value - a.value);

			return stats;
		},
		tipsSenate_Overview: function () {
			if (!this.tipsSenate) return;

			var list = [];

			this.$store.state.static.senate.list.forEach(dis => {
				var obj = {
					about: {
						id: dis.id,
						name: dis.display,
						hash: dis.id + '-' + betterURL(dis.display)
					},
					current: this.$store.state.static.senate.current.find(x => x.id === dis.id),
					list: []
				}

				obj.current.club2 = this.$store.state.static.senate.clubs.find(x => x.hash === obj.current.club);
				obj.current.party2 = this.$(obj.current.party);

				this.tipsSenate.list.filter(x => x.region === dis.id).forEach(item => {
					var o = {
						again: item.again,
						display: item.display,
						color: this.colorOfParty(item.party),
						party: this.$(item.party) || {short: item.party, color: '#ccc'}
					}

					obj.list.push(o);
				});

				obj.list.sort((a, b) => a.display.split(' ')[a.display.split(' ').length - 1].localeCompare(b.display.split(' ')[a.display.split(' ').length - 1], 'cs'));

				list.push(obj);
			});

			list.sort((a, b) => a.about.name.localeCompare(b.about.name, 'cs'));

			return list;
		},
		news: function () {
			return this.tipsSenate ? this.tipsSenate.news : undefined;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL,
		date,
		colorOfParty,
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {path: "", title: "Senátní volby 2022"});
  }
};
