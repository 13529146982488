import axios from 'axios';
import {truncate, date, csuColor} from '@/common/helpers';

export default {
	name: 'mayor-item',
	props: ['data', 'current', 'num', 'seats'],
	components: {},
	data: function () {
		return {
			parties: [],
			mayor: null,
			mayorParty: null,
			comment: null,
			author: null,
			source: null,
			sent: false,
			sending: false
		}
	},
	computed: {
		valid: function () {
			return this.parties.length > 0 && this.mayor != null && this.source != null;
		}
	},
	methods: {
		truncate, date, csuColor,
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2022.programydovoleb.cz/lib/app/api.php?action=town/new', {
					num: this.num,
					coalition: this.parties.join(','),
					comment: this.comment,
					mayor: this.mayor,
					mayorParty: this.mayorParty,
					author: this.author,
					source: this.source
				}).then(() => {
					this.sending = false;
					this.sent = true;
				});
			}
		},
		another: function () {
			this.sent = false;

			this.parties = [];
			this.mayor = null;
			this.mayorParty = null;
			this.source = null;
			this.comment = null;
		}
	}
};
