import Vue from 'vue';

import Page from './page-parts/page/do';
import PageHeader from './page-parts/header/do';
import PageFooter from './page-parts/footer/do';
import PageMain from './page-parts/main/do';

import LayoutDivided from './page-layouts/divided/do';
import LayoutSimple from './page-layouts/simple/do';

import Anchors from './anchors/do';
import Block from './block/do';
import BlockExpandable from './block-expandable/do';
import Headline from './headline/do';
import Image from './image/do';
import Gap from './gap/do';
import Line from './line/do';
import List from './list/do';
import ListLinear from './list-linear/do';
import Columns from './columns/do';
import Area from './area/do';
import Quote from './quote/do';
import Badge from './badge/do';
import Stats from './stats/do';
import Split from './split/do';
import Flex from './flex/do';
import Grid from './grid/do';
import Lang from './lang/do';
import Offset from './offset/do';
import LineGraph from './line-graph/do';

import './box';

Vue.component('p-page', Page);
Vue.component('p-page-header', PageHeader);
Vue.component('p-page-footer', PageFooter);
Vue.component('p-page-main', PageMain);

Vue.component('p-layout-divided', LayoutDivided);
Vue.component('p-layout-simple', LayoutSimple);

Vue.component('p-anchors', Anchors);
Vue.component('p-block', Block);
Vue.component('p-block-expandable', BlockExpandable);
Vue.component('p-headline', Headline);
Vue.component('p-image', Image);
Vue.component('p-gap', Gap);
Vue.component('p-line', Line);
Vue.component('p-list', List);
Vue.component('p-list-linear', ListLinear);
Vue.component('p-columns', Columns);
Vue.component('p-area', Area);
Vue.component('p-quote', Quote);
Vue.component('p-badge', Badge);
Vue.component('p-flex', Flex);
Vue.component('p-grid', Grid);
Vue.component('p-stats', Stats);
Vue.component('p-split', Split);
Vue.component('p-lang', Lang);
Vue.component('p-offset', Offset);
Vue.component('p-line-graph', LineGraph);
